// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .patternBlock {
        width: 100% !important;
        margin: 10px 0 !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .patternBlock {
        width: 100% !important;
        margin: 10px 0 !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .patternBlock {
        width: 48% !important;
        margin: 10px 0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .patternBlock {
        width: 48% !important;
    }
}

@media (min-width: 1200px) {}`, "",{"version":3,"sources":["webpack://./src/components/components/pattern/media.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,qBAAqB;QACrB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ;;AAEA,4BAA4B","sourcesContent":["@media (max-width: 575.98px) {\n    .patternBlock {\n        width: 100% !important;\n        margin: 10px 0 !important;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .patternBlock {\n        width: 100% !important;\n        margin: 10px 0 !important;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {\n    .patternBlock {\n        width: 48% !important;\n        margin: 10px 0;\n    }\n}\n\n@media (min-width: 992px) and (max-width: 1199.98px) {\n    .patternBlock {\n        width: 48% !important;\n    }\n}\n\n@media (min-width: 1200px) {}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
