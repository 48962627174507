import { api } from "API/api";
import { Spin } from "antd";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { fetchLikesUsers } from "store/actions/likesUsers";
import {
  fetchUserMe,
  fetchUserMeExercisesCount,
  fetchUserMeSubscription,
} from "store/actions/userMe";
import CustomModal from "components/components/customModal/CustomModal";
import Footer from "components/components/footer/Footer";
import Header from "components/components/header/Header";
import RegistrationPage from "./views/registrationPage/RegistrationPage";
import LoginPage from "views/loginPage/LoginPage";
import CreateTask from "views/createTask/CreateTask";
import EditProfile from "views/editProfile/EditProfile";
import Faq from "views/faq/Faq";
import Favourites from "views/favourites/Favourites";
import Folder from "views/folder/Folder";
import Main from "views/main/Main";
import MyTasks from "views/myTasks/MyTasks";
import Patterns from "views/patterns/Patterns";
import Privacy from "views/privacy/Privacy";
import Profile from "views/profile/Profile";
import ProfileUser from "views/profile/ProfileUser";
import QuickAuthorization from "views/quickAuthorization/QuickAuthorization";
import ResetPassword from "views/resetPassword/ResetPassword";
import Results from "views/results/Results";
import Search from "views/search/Search";
import Subscriptions from "views/subscriptions/Subscriptions";
import TaskInfo from "views/taskInfo/TaskInfo";
import Tasks from "views/tasks/Tasks";
import { Provider } from "react-redux";

import CreateFolder from "components/forms/createFolder/CreateFolder";
import CreateGroup from "components/forms/createGroup/CreateGroup";
import DeleteExercises from "components/forms/deleteComponents/DeleteExercises";
import DeleteFolder from "components/forms/deleteComponents/DeleteFolder";
import ForgotPassword from "components/forms/forgotPassword/ForgotPassword";
import LoginForm from "components/forms/login/Login";
import Registration from "components/forms/registration/Registration";
import RenameFolder from "components/forms/renameFolder/RenameFolder";
import SearchImages from "components/forms/searchImages/SearchImages";
import SubscribeCancel from "components/forms/subscribeCancel/SubscribeCancel";
import { privacyList } from "lists/privacy";

import loader from "assets/loader.svg";

import "antd/dist/antd.css";
import LetsGoRegister from "components/modal/letsGoRegister/LetsGoRegister";
import SuccessfulRegistration from "components/modal/successfulRegistration/SuccessfulRegistration";
import ViewResult from "components/modal/viewResult/ViewResult";
import "components/style.css";
import "languages/i18next";
import { fetchSubscriptions } from "store/actions/subscriptions";
import Instructions from "views/instructions/Instructions";
import "./App.css";
import SearchPage from "views/searchPage/SearchPage";
import SubscriptionPage from "views/subscriptionPage/SubscriptionPage";
import MainForm from "exercise/components/Forms/MainForm";
import {ForStudent} from "exercise/components/Exercise/fromLink/ForStudent"
import { store } from "exercise";

function App({
  token,
  type,
  search,
  loading,
  pdf_loading,
  fetchUserMe,
  fetchLikesUsers,
  fetchUserMeSubscription,
  fetchUserMeExercisesCount,
  setVisible,
  fetchSubscriptions,
}) {
  const location = useLocation();
  const temp = useRef();
  const startFunction = () => {
    if (token !== null) {
      api.initToken();
      fetchUserMe();
      fetchUserMeSubscription();
      fetchUserMeExercisesCount();
    } else {
      const restrictedPaths = ["/login", "/registration"];
      const isRestrictedTaskRoute = location.pathname.startsWith("/task");

      if (
        !restrictedPaths.includes(location.pathname) &&
        !isRestrictedTaskRoute
      ) {
        setVisible("letsGo_register");
      }
    }
    fetchSubscriptions();
  };

  temp.current = startFunction;

  useEffect(() => {
    temp.current();
  }, []);

  useEffect(() => {
    if (token !== null) {
      fetchLikesUsers();
    }
  }, [token]);

  return (
    <div className="App d-flex flex-column" style={{minHeight: "100%"}}>
      <Header />
      <div className="flex-fill">
        {search.length > 0 ? (
          <Search />
        ) : (
          <Routes>
            <Route path="/" element={<Main />} />
            <Route
              path="/newCreate/:taskType"
              element={
                <Provider store={store}>
                  <MainForm />
                </Provider>
              }
            />
            <Route
              path="/editDraft/:oldId/:double?"
              element={
                <Provider store={store}>
                  <MainForm />
                </Provider>
              }
            />
            <Route
              path="/fromLink/:id"
              element={
                <Provider store={store}>
                  <ForStudent />
                </Provider>
              }
            />
            <Route path="/tasks/:game_type" element={<Tasks />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/subscriptionStandart"
              element={<SubscriptionPage subscriptionType="Standart" />}
            />
            <Route
              path="/subscriptionStandartPlus"
              element={<SubscriptionPage subscriptionType="Standart+" />}
            />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy/:id" element={<Privacy />} />
            <Route
              path="/privacy"
              element={<Privacy doc={privacyList[0].pdf} />}
            />
            <Route
              path="/tariff-plans"
              element={<Privacy doc={privacyList[1].pdf} />}
            />
            <Route
              path="/school-tariff-plans"
              element={<Privacy doc={privacyList[2].pdf} />}
            />
            <Route
              path="/requisites"
              element={<Privacy doc={privacyList[3].pdf} />}
            />
            <Route
              path="/agree"
              element={<Privacy doc={privacyList[4].pdf} />}
            />
            <Route
              path="/payment-policy"
              element={<Privacy doc={privacyList[5].pdf} />}
            />
            <Route path="/patterns" element={<Patterns />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/instructions" element={<Instructions />} />
            <Route path="/task/:id/:group_id" element={<TaskInfo />} />
            <Route path="/profileUser/:id" element={<ProfileUser />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {token !== null && (
              <>
                <Route path="/myTask" element={<MyTasks />} />
                <Route path="/invite" element={<QuickAuthorization />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="profile/edit" element={<EditProfile />} />
                <Route path="profile/favourites" element={<Favourites />} />
                <Route path="/folder/:id" element={<Folder />} />
                <Route path="/create_task" element={<CreateTask />} />
                <Route path="task_results/:id" element={<Results />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </div>
      <Footer />
      {type && (
        <CustomModal>
          {type === "login" && <LoginForm />}
          {type === "registration" && <Registration />}
          {type === "forgotRassword" && <ForgotPassword />}
          {type === "createFolder" && <CreateFolder />}
          {type === "renameFolder" && <RenameFolder />}
          {type === "createGroup" && <CreateGroup />}
          {type === "deleteFolder" && <DeleteFolder />}
          {type === "deleteExercis" && <DeleteExercises />}
          {type === "searchImages" && <SearchImages />}
          {type === "subscribeCancel" && <SubscribeCancel />}
          {type === "results" && <ViewResult />}
          {type === "successful_registration" && <SuccessfulRegistration />}
          {type === "letsGo_register" && <LetsGoRegister />}
        </CustomModal>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    type: state.modal.type,
    search: state.search.search,
    loading: state.userMe.loading,
    pdf_loading: state.pdf.loading,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: (text) => dispatch({ type: "setVisible", payload: text }),
    fetchUserMe: () => dispatch(fetchUserMe()),
    fetchLikesUsers: () => dispatch(fetchLikesUsers()),
    fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription()),
    fetchUserMeExercisesCount: () => dispatch(fetchUserMeExercisesCount()),
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(App);
