// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._3n3wzhjThGa2XY0ytU8Q {
    transform: translate(0px, 70px);
    animation: tOicgAPdzGu0IdfKJXUM 70s linear infinite;
  }
  
  @keyframes tOicgAPdzGu0IdfKJXUM {
    0% {
      transform: translateX(-100%); 
    }
    100% {
      transform: translateX(100%); 
    }
  }
  `, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/themes/animations/CloudAnimation.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mDAAsC;EACxC;;EAEA;IACE;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".cloudAnimation {\n    transform: translate(0px, 70px);\n    animation: moveSvg 70s linear infinite;\n  }\n  \n  @keyframes moveSvg {\n    0% {\n      transform: translateX(-100%); \n    }\n    100% {\n      transform: translateX(100%); \n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
export var cloudAnimation = `_3n3wzhjThGa2XY0ytU8Q`;
export var moveSvg = `tOicgAPdzGu0IdfKJXUM`;
export default ___CSS_LOADER_EXPORT___;
