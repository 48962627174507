// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .favoriteBlock {
        padding: 15px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/favourites/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":["@media (max-width: 575.98px) {\n    .favoriteBlock {\n        padding: 15px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
