// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userBlock {
    width: 100%;
    position: relative;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #EDF0F3;
    border-radius: 8px;
    padding: 0px 24px 0px 0px;
    cursor: pointer;
    align-items: center;
    height: 100%;
}

.user_img img {
    width: 107px;
    height: 107px;
    border-radius: 8px;
}

.userBlock_description {
    padding: 24px;
}

.userBlock_description__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #111827;
}

.userBlock_description__description {
    color: #374151;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/components/userCard/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".userBlock {\n    width: 100%;\n    position: relative;\n    display: flex;\n    background: #FFFFFF;\n    border: 1px solid #EDF0F3;\n    border-radius: 8px;\n    padding: 0px 24px 0px 0px;\n    cursor: pointer;\n    align-items: center;\n    height: 100%;\n}\n\n.user_img img {\n    width: 107px;\n    height: 107px;\n    border-radius: 8px;\n}\n\n.userBlock_description {\n    padding: 24px;\n}\n\n.userBlock_description__title {\n    font-weight: 700;\n    font-size: 24px;\n    line-height: 32px;\n    color: #111827;\n}\n\n.userBlock_description__description {\n    color: #374151;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 18px;\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
