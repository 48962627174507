import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { fetchOneFolder } from 'store/actions/oneFolder';
import { api_folders } from 'API/folders';

import '../style.css';

function RenameFolder({ one_folder, closeModal, fetchOneFolder }) {
  const [fields, setFields] = useState([
    {
      name: 'name',
      value: one_folder.name,
    },
  ]);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    await api_folders.UpdateOneFolder(one_folder.id, values);
    fetchOneFolder(one_folder.id);
    closeModal();
  };

  const onChange = (newFields) => {
    setFields(newFields);
  };

  return (
    <>
      <p className='modalTitle'>{t(`modal.rename_folder.title`)}</p>
      <Form
        layout='vertical'
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete='off'
        className='modalForm'
        fields={fields}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
      >
        <Form.Item
          label={t(`modal.rename_folder.name`)}
          name='name'
          rules={[
            {
              required: true,
              message: t(`modal.rename_folder.name_error`),
            },
          ]}
        >
          <Input className='formInput' />
        </Form.Item>
        <Form.Item>
          <Button className='loginButton' htmlType='submit'>
            {t(`modal.rename_folder.button`)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    one_folder: state.one_folder.one_folder,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    closeModal: () => dispatch({ type: 'closeModal' }),
    fetchOneFolder: (id) => dispatch(fetchOneFolder(id)),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(RenameFolder);
