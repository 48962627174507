// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yAknJDmrIzZJCmBFy1D4 {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  top: 10px;
  left: 20px;
}

.ygH2B47k6Ym94O4xtwXZ {
  width: 120px;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  padding: 0.2rem 0.1rem;
}

.tKB_ZNdKzDpcKapTIGoB {
  outline: 3px solid #cd2222 !important;
}

@media (max-width: 800px) {
  .yAknJDmrIzZJCmBFy1D4 {
    position: relative;
    flex-direction: row;
    justify-content: center;
    top: 0;
    left: 0;
    flex-wrap: wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/Editor/TaskCounter.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,MAAM;IACN,OAAO;IACP,eAAe;EACjB;AACF","sourcesContent":[".taskCounter {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  top: 10px;\n  left: 20px;\n}\n\n.task {\n  width: 120px;\n  font-size: 1rem;\n  text-align: center;\n  font-weight: bold;\n  color: white;\n  border-radius: 4px;\n  padding: 0.2rem 0.1rem;\n}\n\n.bad {\n  outline: 3px solid #cd2222 !important;\n}\n\n@media (max-width: 800px) {\n  .taskCounter {\n    position: relative;\n    flex-direction: row;\n    justify-content: center;\n    top: 0;\n    left: 0;\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var taskCounter = `yAknJDmrIzZJCmBFy1D4`;
export var task = `ygH2B47k6Ym94O4xtwXZ`;
export var bad = `tKB_ZNdKzDpcKapTIGoB`;
export default ___CSS_LOADER_EXPORT___;
