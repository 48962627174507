import currentCheck from 'assets/currentCheck.svg';
import errorCheck from 'assets/errorCheck.svg';

export const tableResultHeader = (game_type) => {
    switch (game_type) {
        case 'TYPE_ANAGRAM':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_QUIZ':
            return <tr>
                <th>Вопрос</th>
                <th>Максимальное кол-во баллов</th>
                <th>Полученные баллы</th>
                <th>Ответы</th>
            </tr>
        case 'TYPE_GROUPS_DIVISION':
            return <tr>
                <th>Вариант</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_INSERT_LETTER':
            return <tr>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_WRITE_ANSWER':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_WRITE_EXTENDED_ANSWER':
            return <tr>
                    <th>Вопрос</th>
                    <th>Ответ пользователя</th>
                    <th>Правильный ответ</th>
                    <th>Результат</th>
            </tr>
        case 'TYPE_FIND_COUPLE':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_MATCH_ELEMENTS':
            return <tr>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_YES_OR_NOT':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_SPACE_MATHEMATICS':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_INSERT_LETTER_SECOND':
            return <tr>
                <th>Вопрос</th>
                <th>Слово</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
        case 'TYPE_CROSSWORD':
            return <tr>
                <th>Вопрос</th>
                <th>Ответ пользователя</th>
                <th>Правильный ответ</th>
                <th>Результат</th>
            </tr>
    }
}

export const tableResultContent = (game_type, item, key) => {
    const chooseExtendedAnswerIcon = (answers, enter_answer) => { 
        if (!answers) { 
            return true
        }
        return answers == enter_answer
    } 

    switch (game_type) {
        case 'TYPE_ANAGRAM':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers.join('')}</td>
                <td>{item.answers.join('')}</td>
                <td>
                    <img src={item.enter_answers.join('') === item.answers.join('') ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_QUIZ':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>{item.max_point}</td>
                <td>{item.actual_point}</td>
                <td>
                    <div style={{ display: 'flex' }}>
                        {item.enter_answers.map((ent, index) => (
                            <div className='quizResultBlock'>
                                {ent.photo && <img key={`enter_answer_photo_${key}_${index}`} src={ent.photo} alt='photo' />}
                                <p key={`enter_answer${key}_${index}`}>{ent.answer}{index < item.enter_answers.length - 1 && ','}&nbsp;</p>
                            </div>
                        ))}
                    </div>
                </td>
            </tr>
        case 'TYPE_GROUPS_DIVISION':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        <p>{item.question.text}</p>
                    </div>
                </td>
                <td>{item.enter_answers}</td>
                <td>{item.answers}</td>
                <td>
                    <img src={item.actual_point !== 0 ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_INSERT_LETTER':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>{item.enter_answer}</td>
                <td>{item.answer}</td>
                <td>
                    <img src={item.actual_point !== 0 ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_WRITE_ANSWER':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers}</td>
                <td>{item.answers}</td>
                <td>
                    <img src={item.enter_answers === item.answers ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_WRITE_EXTENDED_ANSWER':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                    <td>
                        <div className='customTableBlock_table__questionBlock'>
                            {item.question.photo && <img src={item.question.photo} alt="icon" />}
                            {item.question.text && <p>{item.question.text}</p>}
                        </div>
                    </td>
                    <td>{item.enter_answers}</td>
                    <td>{item.answers}</td>
                    <td>
                        <img src={chooseExtendedAnswerIcon(item.answers, item.enter_answers) ? currentCheck : errorCheck} alt="checkAnswer" />
                    </td>
                </tr>
        case 'TYPE_FIND_COUPLE':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        {item.enter_answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.enter_answers.answer_photo} alt='photo' />}
                        {item.enter_answers.answer && <p key={`enter_answer${key}`}>{item.enter_answers.answer}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        {item.answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.answers.answer_photo} alt='photo' />}
                        {item.answers.answer && <p key={`enter_answer${key}`}>{item.answers.answer}</p>}
                    </div>
                </td>
                <td>
                    <img src={(item.enter_answers.answer === item.answers.answer && item.enter_answers.answer_photo === item.answers.answer_photo) ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_MATCH_ELEMENTS':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center' }}>
                        {JSON.parse(item.enter_answers)?.value !== 'Не знаю' && <p key={`enter_question${key}`}>{item.question.text.toUpperCase()}</p>}
                        {JSON.parse(item.enter_answers)?.img && <img key={`enter_answer_photo_${key}`} src={JSON.parse(item.enter_answers).img} alt='photo' />}
                        {JSON.parse(item.enter_answers)?.value && <p key={`enter_answer${key}`}>{JSON.parse(item.enter_answers).value?.toUpperCase()}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center' }}>
                        <p key={`enter_question${key}`}>{item.question.text.toUpperCase()}</p>
                        {item.answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.answers.answer_photo} alt='photo' />}
                        {item.answers.second_half && <p key={`enter_answer${key}`}>{item.answers.second_half.toUpperCase()}</p>}
                    </div>
                </td>
                <td>
                    <img src={item.answers.second_half === (JSON.parse(item.enter_answers)?.value?.toUpperCase() || null) && item.answers.answer_photo === JSON.parse(item.enter_answers)?.img ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_YES_OR_NOT':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers}</td>
                <td>{item.answers}</td>
                <td>
                    <img src={item.enter_answers === item.answers ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_SPACE_MATHEMATICS':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    {item.question.text}
                </td>
                <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {item.enter_answers.map((ent, index) => (
                            <p key={`enter_answer${key}_${index}`}>{ent}{index < item.enter_answers.length - 1 && ','}&nbsp;</p>
                        ))}
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {item.answers.map((ent, index) => (
                            <p key={`enter_answer${key}_${index}`}>{ent}{index < item.answers.length - 1 && ','}&nbsp;</p>
                        ))}
                    </div>
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_INSERT_LETTER_SECOND':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>
                    {item.question.question}
                </td>
                <td>
                    {item.enter_answers}
                </td>
                <td>
                    {item.answers}
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_CROSSWORD':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.question.photo && <img src={item.question.photo} alt="icon" />}
                        {item.question.text && <p>{item.question.text}</p>}
                    </div>
                </td>
                <td>
                    {item.enter_answers}
                </td>
                <td>
                    {item.answers}
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
    }
}

export const tableleaderboardResultContent = (game_type, item, key) => {
    switch (game_type) {
        case 'TYPE_ANAGRAM':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers.enter_answers.join('')}</td>
                <td>{item.answers.answers.join('')}</td>
                <td>
                    <img src={item.enter_answers.enter_answers.join('') === item.answers.answers.join('') ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_QUIZ':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>{item.max_point}</td>
                <td>{item.actual_point}</td>
                <td>
                    <div style={{ display: 'flex' }}>
                        {item.enter_answers.enter_answers.map((ent, index) => {
                            if (typeof ent === 'object') {
                                return <div className='quizResultBlock'>
                                    {ent.photo && <img key={`enter_answer_photo_${key}_${index}`} src={ent.photo} alt='photo' />}
                                    <p key={`enter_answer${key}_${index}`}>{ent.answer}{index < item.enter_answers.enter_answers.length - 1 && ','}&nbsp;</p>
                                </div>
                            } else {
                                return <p key={`enter_answer${key}_${index}`}>{ent}{index < item.enter_answers.enter_answers.length - 1 && ','}&nbsp;</p>
                            }
                        })}
                    </div>
                </td>
            </tr>
        case 'TYPE_GROUPS_DIVISION':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        <p>{item.text}</p>
                    </div>
                </td>
                <td>{item.enter_answers.enter_answers}</td>
                <td>{item.answers.answers}</td>
                <td>
                    <img src={item.actual_point !== 0 ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_INSERT_LETTER':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>{item.enter_answer.enter_answer}</td>
                <td>{item.answer.answer}</td>
                <td>
                    <img src={item.actual_point !== 0 ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_WRITE_ANSWER':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers.enter_answers}</td>
                <td>{item.answers.answers}</td>
                <td>
                    <img src={item.enter_answers.enter_answers === item.answers.answers ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_FIND_COUPLE':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        {item.enter_answers.enter_answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.enter_answers.enter_answers.answer_photo} alt='photo' />}
                        {item.enter_answers.enter_answers.answer && <p key={`enter_answer${key}`}>{item.enter_answers.enter_answers.answer}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        {item.answers.answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.answers.answers.answer_photo} alt='photo' />}
                        {item.answers.answers.answer && <p key={`enter_answer${key}`}>{item.answers.answers.answer}</p>}
                    </div>
                </td>
                <td>
                    <img src={(item.enter_answers.enter_answers.answer === item.answers.answers.answer && item.enter_answers.enter_answers.answer_photo === item.answers.answers.answer_photo) ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_MATCH_ELEMENTS':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center' }}>
                        <p key={`enter_question${key}`}>{item.text.toUpperCase()}</p>
                        {JSON.parse(item.enter_answers.enter_answers)?.img && <img key={`enter_answer_photo_${key}`} src={JSON.parse(item.enter_answers.enter_answers).img} alt='photo' />}
                        {JSON.parse(item.enter_answers.enter_answers)?.value && <p key={`enter_answer${key}`}>{JSON.parse(item.enter_answers.enter_answers).value?.toUpperCase()}</p>}
                    </div>
                </td>
                <td>
                    <div className='quizResultBlock' style={{ justifyContent: 'center' }}>
                        <p key={`enter_question${key}`}>{item.text.toUpperCase()}</p>
                        {item.answers.answers.answer_photo && <img key={`enter_answer_photo_${key}`} src={item.answers.answers.answer_photo} alt='photo' />}
                        {item.answers.answers.second_half && <p key={`enter_answer${key}`}>{item.answers.answers.second_half.toUpperCase()}</p>}
                    </div>
                </td>
                <td>
                <img src={item.answers.answers.second_half === (JSON.parse(item.enter_answers.enter_answers)?.value?.toUpperCase() || null) && item.answers.answers.answer_photo === JSON.parse(item.enter_answers.enter_answers)?.img ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_YES_OR_NOT':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>{item.enter_answers.enter_answers}</td>
                <td>{item.answers.answers}</td>
                <td>
                    <img src={item.enter_answers.enter_answers === item.answers.answers ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_SPACE_MATHEMATICS':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    {item.text}
                </td>
                <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {item.enter_answers.enter_answers.map((ent, index) => (
                            <p key={`enter_answer${key}_${index}`}>{ent}{index < item.enter_answers.enter_answers.length - 1 && ','}&nbsp;</p>
                        ))}
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {item.answers.answers.map((ent, index) => (
                            <p key={`enter_answer${key}_${index}`}>{ent}{index < item.answers.answers.length - 1 && ','}&nbsp;</p>
                        ))}
                    </div>
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_INSERT_LETTER_SECOND':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>
                    {item.answers.question}
                </td>
                <td>
                    {item.enter_answers.enter_answers}
                </td>
                <td>
                    {item.answers.answers}
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
        case 'TYPE_CROSSWORD':
            return <tr key={`resultTable${key}`} className={`${key % 2 !== 0 ? 'not_even_row' : ''}`}>
                <td>
                    <div className='customTableBlock_table__questionBlock'>
                        {item.photo && <img src={item.photo} alt="icon" />}
                        {item.text && <p>{item.text}</p>}
                    </div>
                </td>
                <td>
                    {item.enter_answers.enter_answers}
                </td>
                <td>
                    {item.answers.answers}
                </td>
                <td>
                    <img src={item.actual_point === item.max_point ? currentCheck : errorCheck} alt="checkAnswer" />
                </td>
            </tr>
    }
}