// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I551gO5qHSTgzuTkYdHE {
  padding: 0.3rem 0.1rem;
  color: white;
  font-family: "Rubick";
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #22cd29;
  text-align: center;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/variants/DecartForm.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".addRow {\n  padding: 0.3rem 0.1rem;\n  color: white;\n  font-family: \"Rubick\";\n  font-size: 1.1rem;\n  font-weight: 600;\n  background-color: #22cd29;\n  text-align: center;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export var addRow = `I551gO5qHSTgzuTkYdHE`;
export default ___CSS_LOADER_EXPORT___;
