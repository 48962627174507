// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBlock_title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
}

.search_list {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr)) !important;
    gap: 15px;
}

.search_list__item {
    display: flex;
    justify-content: center;
}

.hashtags_list {
    display: flex;
    gap: 5px 6px;
    flex-wrap: wrap;
    margin-top: 12px;
}

.search_list__hashtagsItem {
    display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 10px;
    background: #9B9D9E;
    color: #FFF;
    width: fit-content;
    cursor: pointer;
}

.search_list__hashtagsItem__active {
    background: #7C3AED;
}`, "",{"version":3,"sources":["webpack://./src/views/search/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,uEAAuE;IACvE,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".searchBlock_title {\n    font-weight: 700;\n    font-size: 48px;\n    line-height: 60px;\n}\n\n.search_list {\n    display: grid !important;\n    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr)) !important;\n    gap: 15px;\n}\n\n.search_list__item {\n    display: flex;\n    justify-content: center;\n}\n\n.hashtags_list {\n    display: flex;\n    gap: 5px 6px;\n    flex-wrap: wrap;\n    margin-top: 12px;\n}\n\n.search_list__hashtagsItem {\n    display: flex;\n    padding: 0px 8px;\n    align-items: flex-start;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    border-radius: 10px;\n    background: #9B9D9E;\n    color: #FFF;\n    width: fit-content;\n    cursor: pointer;\n}\n\n.search_list__hashtagsItem__active {\n    background: #7C3AED;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
