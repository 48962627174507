// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yesOrNotTasksBlock {
    display: flex;
    justify-content: space-between;
}

.yesOrNotTasksBlock_item {
    padding: 24px;
    background: #FAFAFA;
    border-radius: 12px;
    width: 48%;
}

.yesOrNotTasksBlock_item__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px !important;
}

.yesOrNotTasksBlock_item .form_matchElementquestionBlock .form_matchElementquestionBlock__item {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/games/yesOrNot.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,8BAA8B;AAClC;;AAEA;IACI,SAAS;AACb","sourcesContent":[".yesOrNotTasksBlock {\n    display: flex;\n    justify-content: space-between;\n}\n\n.yesOrNotTasksBlock_item {\n    padding: 24px;\n    background: #FAFAFA;\n    border-radius: 12px;\n    width: 48%;\n}\n\n.yesOrNotTasksBlock_item__title {\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 28px;\n    margin-bottom: 10px !important;\n}\n\n.yesOrNotTasksBlock_item .form_matchElementquestionBlock .form_matchElementquestionBlock__item {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
