// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FtJbu8grIyIJf7xl3Ujj {
  gap: 28px;
}

.kPjJ4niQ380dEgY1GLCJ {
  z-index: 5;
}

@media (max-width: 450px) {
  .FtJbu8grIyIJf7xl3Ujj {
    gap: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/resolveMode/ExerciseResolveModeFooter.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".footerSteps {\n  gap: 28px;\n}\n\n.footer {\n  z-index: 5;\n}\n\n@media (max-width: 450px) {\n  .footerSteps {\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var footerSteps = `FtJbu8grIyIJf7xl3Ujj`;
export var footer = `kPjJ4niQ380dEgY1GLCJ`;
export default ___CSS_LOADER_EXPORT___;
