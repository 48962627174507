// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JTOKCZXL6IDuKPBo8r4S {
  width: 120px;
  padding: 9px 14px;
  font-size: 14px;
  color: white;
  line-height: 110%;
  font-family: "DelaGothicOne";
  background-color: #002876;
  border-radius: 4px;
}

.JTOKCZXL6IDuKPBo8r4S:active {
  transform: translateY(2px);
}

/* 
@media (min-width: 1200px) {
  .headerButton {
    font-size: 20px;
  }
} */
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/resolveMode/ExerciseResolveModeHeader.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;;;;GAKG","sourcesContent":[".headerButton {\n  width: 120px;\n  padding: 9px 14px;\n  font-size: 14px;\n  color: white;\n  line-height: 110%;\n  font-family: \"DelaGothicOne\";\n  background-color: #002876;\n  border-radius: 4px;\n}\n\n.headerButton:active {\n  transform: translateY(2px);\n}\n\n/* \n@media (min-width: 1200px) {\n  .headerButton {\n    font-size: 20px;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
export var headerButton = `JTOKCZXL6IDuKPBo8r4S`;
export default ___CSS_LOADER_EXPORT___;
