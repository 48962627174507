// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o2TAPrTmDVji9cFCmqK_ {
  padding: 0.3rem;
  border: 1px solid var(--darkBlue);
  border-radius: 5px;
  text-align: center;
  font-size: 1.1rem;
  color: var(--darkBlue);
  cursor: pointer;
  width: 100%;
}

.XveXSDO75jR42At5u4Yf {
  cursor: pointer;
  width: 100%;
  padding: 0.3rem;
  border: 1px solid rgb(34, 205, 41);
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(34, 205, 41);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/variants/TextOrImage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iCAAiC;EACjC,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;EACf,kCAAkC;EAClC,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".changeType {\n  padding: 0.3rem;\n  border: 1px solid var(--darkBlue);\n  border-radius: 5px;\n  text-align: center;\n  font-size: 1.1rem;\n  color: var(--darkBlue);\n  cursor: pointer;\n  width: 100%;\n}\n\n.add {\n  cursor: pointer;\n  width: 100%;\n  padding: 0.3rem;\n  border: 1px solid rgb(34, 205, 41);\n  border-radius: 5px;\n  font-size: 1.1rem;\n  font-weight: 500;\n  color: rgb(34, 205, 41);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var changeType = `o2TAPrTmDVji9cFCmqK_`;
export var add = `XveXSDO75jR42At5u4Yf`;
export default ___CSS_LOADER_EXPORT___;
