"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variantsValidateAndAll = exports.FormVariants = exports.FormType = void 0;
const DecartForm_1 = require("./DecartForm");
const SimpleAnswerForm_1 = require("./SimpleAnswerForm");
const TextOrImageForm_1 = require("./TextOrImageForm");
const validateAndAll_1 = require("./validateAndAll");
var FormType;
(function (FormType) {
    FormType["DECART"] = "decart";
    FormType["SIMPLE_ANSWER"] = "simpleAnswer";
    FormType["TEXT_OR_IMAGE"] = "textOrImage";
})(FormType || (exports.FormType = FormType = {}));
exports.FormVariants = {
    [FormType.DECART]: DecartForm_1.DecartForm,
    [FormType.SIMPLE_ANSWER]: SimpleAnswerForm_1.SimpleAnswerForm,
    [FormType.TEXT_OR_IMAGE]: TextOrImageForm_1.TextOrImageForm,
};
exports.variantsValidateAndAll = {
    [FormType.DECART]: {
        all: validateAndAll_1.decartAll,
        validate: validateAndAll_1.decartValidate,
    },
    [FormType.SIMPLE_ANSWER]: {
        all: validateAndAll_1.simpleAnswerAll,
        validate: validateAndAll_1.simpleAnswerValidate,
    },
    [FormType.TEXT_OR_IMAGE]: {
        all: validateAndAll_1.textOrImageAll,
        validate: validateAndAll_1.textOrImageValidate,
    },
};
