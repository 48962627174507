// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VYvNtMFCC6CTLpaiXqw5 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  gap: 1rem;
  font-family: "Rubick";
  margin: 0 auto;
  padding-bottom: 2.5rem;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.aLnsEg1pNxF14hr9FuTz {
  color: var(--darkBlue);
  text-align: center;
  font-size: 2rem;
}

.zLC1sIGxc8sT_Pm2DJl4 {
  background-color: #ff9800;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  padding: 0.3rem 0.1rem;
  cursor: pointer;
  margin-top: 0.4rem;
}

.KLnpdQeTEY7LxVUdmCx1 {
  padding: 0.2rem;
  color: white;
  border-radius: 5px;
  background-color: #28B1FF;
  text-align: center;
  font-family: "Rubick";
  font-size: 1.4rem;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/MainForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,SAAS;EACT,qBAAqB;EACrB,cAAc;EACd,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".mainForm {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 1200px;\n  gap: 1rem;\n  font-family: \"Rubick\";\n  margin: 0 auto;\n  padding-bottom: 2.5rem;\n  padding-top: 1rem;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n}\n\n.title {\n  color: var(--darkBlue);\n  text-align: center;\n  font-size: 2rem;\n}\n\n.addTask {\n  background-color: #ff9800;\n  border-radius: 4px;\n  color: white;\n  font-weight: 600;\n  font-size: 1rem;\n  text-align: center;\n  padding: 0.3rem 0.1rem;\n  cursor: pointer;\n  margin-top: 0.4rem;\n}\n\n.formTitle {\n  padding: 0.2rem;\n  color: white;\n  border-radius: 5px;\n  background-color: #28B1FF;\n  text-align: center;\n  font-family: \"Rubick\";\n  font-size: 1.4rem;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export var mainForm = `VYvNtMFCC6CTLpaiXqw5`;
export var title = `aLnsEg1pNxF14hr9FuTz`;
export var addTask = `zLC1sIGxc8sT_Pm2DJl4`;
export var formTitle = `KLnpdQeTEY7LxVUdmCx1`;
export default ___CSS_LOADER_EXPORT___;
