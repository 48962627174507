// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ydatoY_hMSSE3KOQBLTZ {
  text-align: center;
}

.ydatoY_hMSSE3KOQBLTZ {
  background-color: white;
  padding: 6px;
}

.owFAPdq_b5svbw68vcRy {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/resultMode/ResultsHeader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".resultHeader {\n  text-align: center;\n}\n\n.resultHeader {\n  background-color: white;\n  padding: 6px;\n}\n\n.infoBlock {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var resultHeader = `ydatoY_hMSSE3KOQBLTZ`;
export var infoBlock = `owFAPdq_b5svbw68vcRy`;
export default ___CSS_LOADER_EXPORT___;
