// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cloudflake {
    display: inline-block;
	width: 5%;
	font-size: 30px;
	margin: 0;
	padding: 0;
    color: white;
    text-shadow: 1px 1px 1px rgb(233, 233, 119);
    position: absolute;
}

.cloudAnimation {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/animation/cloud/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;CACxB,SAAS;CACT,eAAe;CACf,SAAS;CACT,UAAU;IACP,YAAY;IACZ,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;CAChB,8BAA8B;CAC9B,SAAS;CACT,UAAU;CACV,gBAAgB;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;MACE,UAAU;IACZ;EACF","sourcesContent":[".Cloudflake {\n    display: inline-block;\n\twidth: 5%;\n\tfont-size: 30px;\n\tmargin: 0;\n\tpadding: 0;\n    color: white;\n    text-shadow: 1px 1px 1px rgb(233, 233, 119);\n    position: absolute;\n}\n\n.cloudAnimation {\n    background-repeat: no-repeat;\n    background-size: cover;\n    display: flex;\n\tjustify-content: space-between;\n\tmargin: 0;\n\tpadding: 0;\n\toverflow: hidden;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 25px;\n}\n\n@keyframes blinker {\n    50% {\n      opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
