// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .fioBlock {
        display: block;
    }
    .editProfileForm{
        width: 100%;
    }
    .editProfileForm_avatarBlock{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .editProfileForm_avatarBlock button{
        margin-top: 15px;
    }
    .editProfileForm_avatarBlock__img{
        margin-right: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .editProfileForm{
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}`, "",{"version":3,"sources":["webpack://./src/components/forms/media.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;IAClB;IACA;QACI,WAAW;IACf;IACA;QACI,sBAAsB;QACtB,mBAAmB;QACnB,SAAS;IACb;IACA;QACI,gBAAgB;IACpB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA,qDAAqD;;AAErD,sDAAsD;;AAEtD,4BAA4B","sourcesContent":["@media (max-width: 575.98px) {\n    .fioBlock {\n        display: block;\n    }\n    .editProfileForm{\n        width: 100%;\n    }\n    .editProfileForm_avatarBlock{\n        flex-direction: column;\n        align-items: center;\n        gap: 10px;\n    }\n    .editProfileForm_avatarBlock button{\n        margin-top: 15px;\n    }\n    .editProfileForm_avatarBlock__img{\n        margin-right: 0;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .editProfileForm{\n        width: 100%;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {}\n\n@media (min-width: 992px) and (max-width: 1199.98px) {}\n\n@media (min-width: 1200px) {}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
