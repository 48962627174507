// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folderPage_header {
    display: flex;
    align-items: center;
}

.folderPage_header .deleteIcon {
    margin-left: 10px;
}

.folderPage_header p {
    margin-left: 26px;
    margin-right: 19px;
}

.folderPage_header img {
    cursor: pointer;
}

.folderTasks_list {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr)) !important;
    gap: 15px;
    margin-top: 20px !important;
}

.folderTasks_list__item {
    display: flex;
    justify-content: center;
}

@media (max-width: 575.98px) {
    .myTask {
        padding: 20px !important;
    }

    .folderPage_headerBlock {
        align-items: flex-start !important;
    }

    .folderPage_header {
        flex-direction: row !important;
        gap: 20px;
        align-items: center !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .folderPage_headerBlock {
        align-items: flex-start !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/folder/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,uEAAuE;IACvE,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,kCAAkC;IACtC;;IAEA;QACI,8BAA8B;QAC9B,SAAS;QACT,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,kCAAkC;IACtC;AACJ","sourcesContent":[".folderPage_header {\n    display: flex;\n    align-items: center;\n}\n\n.folderPage_header .deleteIcon {\n    margin-left: 10px;\n}\n\n.folderPage_header p {\n    margin-left: 26px;\n    margin-right: 19px;\n}\n\n.folderPage_header img {\n    cursor: pointer;\n}\n\n.folderTasks_list {\n    display: grid !important;\n    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr)) !important;\n    gap: 15px;\n    margin-top: 20px !important;\n}\n\n.folderTasks_list__item {\n    display: flex;\n    justify-content: center;\n}\n\n@media (max-width: 575.98px) {\n    .myTask {\n        padding: 20px !important;\n    }\n\n    .folderPage_headerBlock {\n        align-items: flex-start !important;\n    }\n\n    .folderPage_header {\n        flex-direction: row !important;\n        gap: 20px;\n        align-items: center !important;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .folderPage_headerBlock {\n        align-items: flex-start !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
