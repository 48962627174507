"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetPositions = useSetPositions;
const react_redux_1 = require("react-redux");
function useSetPositions(indexName, id) {
    const dispatch = (0, react_redux_1.useDispatch)();
    const setFormPositions = (pos, removeProblems) => {
        dispatch.form.setPositions({
            indexName,
            id,
            positions: Object.assign(Object.assign({}, pos), { id: id }),
        }, removeProblems);
    };
    return setFormPositions;
}
