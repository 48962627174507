// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPassworbBlock {
    max-width: 500px;
    padding: 25px;
    background-color: white;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 11;
}`, "",{"version":3,"sources":["webpack://./src/views/resetPassword/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".resetPassworbBlock {\n    max-width: 500px;\n    padding: 25px;\n    background-color: white;\n    border-radius: 25px;\n    margin-left: auto;\n    margin-right: auto;\n    position: relative;\n    z-index: 11;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
