// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vaFU0Bk3X4YJ2XqsOe8Y {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
}

.T7fHYzvb3mSllGgRaNN4 {
    color: #111827;
    font-feature-settings: 'liga' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.7px;
}

.W0EyglO8ob8zCfp4esHA {
    color: #111827;
    font-feature-settings: 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.qH1jxCly3VtZHeN0liRp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #7C3AED;
    padding: 12px;
    gap: 8px;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-wrap: nowrap;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/letsGoRegister/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,cAAc;IACd,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".letGoRegister {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 24px;\n}\n\n.letGoRegister__title {\n    color: #111827;\n    font-feature-settings: 'liga' off;\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 48px;\n    letter-spacing: -0.7px;\n}\n\n.letGoRegister__description {\n    color: #111827;\n    font-feature-settings: 'liga' off;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n}\n\n.letGoRegister__access_buttons_purple {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex: 1 0 0;\n    border-radius: 8px;\n    background: #7C3AED;\n    padding: 12px;\n    gap: 8px;\n    color: #FFF;\n    text-align: center;\n    font-feature-settings: 'liga' off;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px;\n    text-wrap: nowrap;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export var letGoRegister = `vaFU0Bk3X4YJ2XqsOe8Y`;
export var letGoRegister__title = `T7fHYzvb3mSllGgRaNN4`;
export var letGoRegister__description = `W0EyglO8ob8zCfp4esHA`;
export var letGoRegister__access_buttons_purple = `qH1jxCly3VtZHeN0liRp`;
export default ___CSS_LOADER_EXPORT___;
