// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfCircle {
    width: 20px;
    height: 20px;
    border: 2px solid #D1D5DB;
    box-sizing: border-box;
    border-radius: 100%;
    margin: 0 12px;
}

.pdfLine {
    width: 30mm;
    border-bottom: 2px solid #000;
    margin-right: 2mm;
    margin-left: 2mm;
}

.pdfSquare {
    border: 0.5mm solid #000000;
    margin: 1mm;
    width: 6mm;
    height: 6mm;
}

.pdfTaskNumber {
    color: rgba(0, 0, 0, 0.56);
    margin-right: 3mm;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,UAAU;IACV,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;AACrB","sourcesContent":[".pdfCircle {\n    width: 20px;\n    height: 20px;\n    border: 2px solid #D1D5DB;\n    box-sizing: border-box;\n    border-radius: 100%;\n    margin: 0 12px;\n}\n\n.pdfLine {\n    width: 30mm;\n    border-bottom: 2px solid #000;\n    margin-right: 2mm;\n    margin-left: 2mm;\n}\n\n.pdfSquare {\n    border: 0.5mm solid #000000;\n    margin: 1mm;\n    width: 6mm;\n    height: 6mm;\n}\n\n.pdfTaskNumber {\n    color: rgba(0, 0, 0, 0.56);\n    margin-right: 3mm;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
