"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allValidators = exports.Validators = void 0;
const notEmpty_1 = require("./notEmpty");
var Validators;
(function (Validators) {
    Validators["NO_EMPTY"] = "noEmpty";
})(Validators || (exports.Validators = Validators = {}));
exports.allValidators = {
    'noEmpty': notEmpty_1.notEmpty
};
