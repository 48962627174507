"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkExercise = void 0;
const resolveZod_1 = require("./resolveZod");
const checkExercise = (incomingObject) => {
    const result = resolveZod_1.exerciseSchema.safeParse(incomingObject);
    console.error(result.error, 'error');
    return result.success;
};
exports.checkExercise = checkExercise;
