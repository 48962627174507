import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Pattern from "components/components/pattern/Pattern";
import { patternsGame } from "lists/list";

import background from "assets/backgrounds/main.png";
import purpuleArrow from "assets/purpuleArrow.svg";

import "../media.css";
import "./style.css";

function Patterns({ token, setVisible, stepUp, selectTaskPattern }) {
  const [patterns, setpattarns] = useState(patternsGame);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickPattern = (type) => {
    if (token === null) {
      setVisible("login");
    } else {
      selectTaskPattern(type);
      stepUp();
      navigate(`../create_task`);
    }
  };

  const onClickNewPattern = (type) => {
    if (token === null) {
      setVisible("login");
    } else {
      navigate(`/newCreate/${type}`);
    }
  };

  const searchPatterns = (e) => {
    if (e === "") {
      setpattarns(patternsGame);
    } else {
      const seartharray = [];
      for (let i = 0; i < patternsGame.length; i++) {
        let count = 0;
        for (let j = 0; j < e.length; j++) {
          if (patternsGame[i].title[j] === e[j]) {
            count++;
          }
        }
        if (count === e.length) {
          seartharray.push(patternsGame[i]);
        }
      }
      setpattarns(seartharray);
    }
  };

  return (
    <>
      <img src={background} className="mainMiniImg" />
      <div className="patternsBlock">
        <div className="patternsBlock_header">
          <p className="patternsBlock_header__title">{t(`patterns.title`)}</p>
          <Input
            className="header_actionBlock__input"
            placeholder={t(`header.search_placeholder`)}
            prefix={<SearchOutlined />}
            onChange={(e) => searchPatterns(e.target.value)}
          />
        </div>
        <div className="patternsBlock_list">
          {patterns.map((item, key) => {
            return (
              <Pattern
                item={item}
                key={key}
                onClick={
                  item.new
                    ? () => onClickNewPattern(item.type)
                    : () => onClickPattern(item.type)
                }
              />
            );
          })}
        </div>
        {token === null && (
          <p className="patternsBlock_button">
            {t(`patterns.button`)} <img src={purpuleArrow} alt="purpuleArrow" />
          </p>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: (text) => dispatch({ type: "setVisible", payload: text }),
    stepUp: () => dispatch({ type: "stepUp" }),
    selectTaskPattern: (text) =>
      dispatch({ type: "selectTaskPattern", payload: text }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(Patterns);
