// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insertLetterPdf {
    margin-bottom: 1.5cm;
}

.insertLetterPdf_question {
    display: flex;
    flex-wrap: wrap;
}

.insertLetterPdf_question p {
    margin-right: 4mm;
}

.insertLetterPdf_answers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1cm;
}

.insertLetterPdf_answers p {
    margin-right: 6mm;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/insertLetter/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".insertLetterPdf {\n    margin-bottom: 1.5cm;\n}\n\n.insertLetterPdf_question {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.insertLetterPdf_question p {\n    margin-right: 4mm;\n}\n\n.insertLetterPdf_answers {\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 1cm;\n}\n\n.insertLetterPdf_answers p {\n    margin-right: 6mm;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
