// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptionBlock {
    padding: 0 60px;
    position: relative;
    z-index: 1000;
}

.subscriptionBlock_title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 100px;
}

.pricesBlock {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 48px;
    margin-bottom: 64px;
}

@media (max-width: 575.98px) {
    .subscriptionBlock_title {
        margin-top: 0 !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .subscriptionBlock_title {
        margin-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .subscriptionBlock_title {
        margin-top: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/subscriptions/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".subscriptionBlock {\n    padding: 0 60px;\n    position: relative;\n    z-index: 1000;\n}\n\n.subscriptionBlock_title {\n    font-weight: 700;\n    font-size: 48px;\n    line-height: 60px;\n    color: #FFFFFF;\n    text-align: center;\n    margin-top: 100px;\n}\n\n.pricesBlock {\n    margin-top: 64px;\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 48px;\n    margin-bottom: 64px;\n}\n\n@media (max-width: 575.98px) {\n    .subscriptionBlock_title {\n        margin-top: 0 !important;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .subscriptionBlock_title {\n        margin-top: 0;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {\n    .subscriptionBlock_title {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
