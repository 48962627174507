import arrow from 'assets/arrow.svg';
import greenOk from 'assets/greenOk.svg';
import purpuleArrow from 'assets/purpuleArrow.svg';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSubscribe, fetchSubscriptions } from 'store/actions/subscriptions';
import * as styles from './style.module.css';

const SuccessfulRegistration = ({
    setVisible,
    fetchSubscribe,
    fetchSubscriptions,
    subscriptions
}) => {

    useEffect(() => {
        fetchSubscriptions()
    }, [])

    const subscribe = () => {
        fetchSubscribe(subscriptions.find(el => el.name === 'Standart').id)
            .then(res => {
                if (res) {
                    window.open(res, '_blank');
                }
            })
        setVisible()
    }

    return (subscriptions &&
        <div className={styles.successfulRegistration}>
            <p className={styles.successfulRegistration__title}>Вы успешно зарегистрировались</p>
            <div className={styles.successfulRegistration__notification}>Вам доступен 14 дневный пробный период с доступом ко всем шаблонам!</div>
            <div className={styles.successfulRegistration__access}>
                <p className={styles.successfulRegistration__access_title}>Вам доступны:</p>
                <div className={styles.successfulRegistration__access_row}>
                    <img src={greenOk} alt='greenOk' />
                    <p className={styles.successfulRegistration__access_row_title}>Шаблоны игр : 14</p>
                </div>
                <div className={styles.successfulRegistration__access_row}>
                    <img src={greenOk} alt='greenOk' />
                    <p className={styles.successfulRegistration__access_row_title}>Печатные материалы: 11</p>
                </div>
                <div className={styles.successfulRegistration__access_row}>
                    <img src={greenOk} alt='greenOk' />
                    <p className={styles.successfulRegistration__access_row_title}>Количество ресурсов, которые вы можете создать: Без ограничений</p>
                </div>
            </div>
            <div className={styles.successfulRegistration__access_buttons}>
                <button className={styles.successfulRegistration__access_buttons_lightPurple} onClick={setVisible}>
                    Продолжить
                    <img src={purpuleArrow} alt="purpuleArrow" />
                </button>
                <button className={styles.successfulRegistration__access_buttons_purple} onClick={subscribe}>
                    Оформить подписку
                    <img src={arrow} alt="purpuleArrow" />
                </button>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions.subscriptions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: 'closeModal' }),
        fetchSubscribe: (id) => dispatch(fetchSubscribe(id)),
        fetchSubscriptions: () => dispatch(fetchSubscriptions())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulRegistration)