// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crosswordPuzzleBgTable {
    display: inline-flex;
    flex-direction: column;
}

.crosswordPuzzleBgTable tr td {
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    width: 18px;
    height: 18px;
}

.crosswordPuzzleBgTable_input {
    background: #FFFFFF;
    border-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.crosswordPuzzleBgTable_input input {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    text-align: center;
    outline: none !important;
    border: 1px solid white;
}

.crosswordBgAnswerNumber {
    position: absolute;
    top: 0;
    font-weight: 500;
    font-size: 6px;
    line-height: 10px;
    color: #989898;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/crosswordPuzzle/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,wBAAwB;IACxB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".crosswordPuzzleBgTable {\n    display: inline-flex;\n    flex-direction: column;\n}\n\n.crosswordPuzzleBgTable tr td {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 14px;\n    text-align: center;\n    width: 18px;\n    height: 18px;\n}\n\n.crosswordPuzzleBgTable_input {\n    background: #FFFFFF;\n    border-radius: 4px;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    position: relative;\n}\n\n.crosswordPuzzleBgTable_input input {\n    width: 16px;\n    height: 16px;\n    border-radius: 4px;\n    text-align: center;\n    outline: none !important;\n    border: 1px solid white;\n}\n\n.crosswordBgAnswerNumber {\n    position: absolute;\n    top: 0;\n    font-weight: 500;\n    font-size: 6px;\n    line-height: 10px;\n    color: #989898;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
