// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cQ3JUZYrkhAVm51mi9J6 {
  min-width: 120px;
  padding: 0.2rem 0.3rem;
  text-align: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}


`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/FormFooter.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".button {\n  min-width: 120px;\n  padding: 0.2rem 0.3rem;\n  text-align: center;\n  font-weight: bold;\n  color: white;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export var button = `cQ3JUZYrkhAVm51mi9J6`;
export default ___CSS_LOADER_EXPORT___;
