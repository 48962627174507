// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageBlock {
    background: linear-gradient(180deg, #3AC2ED 0%, #3A9FED 54.17%, #6955ED 100%);
    position: relative;
    z-index: 11;
}

.stepsBackground {
    position: absolute;
    top: 420px;
    height: 980px;
}

.caruselBackground {
    position: absolute;
    top: 770px;
    right: 0;
}

.stepsBlock {
    margin-top: 115px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 60px;
    position: relative;
    z-index: 11;
}

.videoBlock {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    text-align: center;
    background-size: cover;
}

.react-player__preview {
    background-size: cover !important;
}

.videoBlock img {
    width: 80px;
    /* margin-top: 228px; */
}`, "",{"version":3,"sources":["webpack://./src/views/main/style.css"],"names":[],"mappings":"AAAA;IACI,6EAA6E;IAC7E,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".mainPageBlock {\n    background: linear-gradient(180deg, #3AC2ED 0%, #3A9FED 54.17%, #6955ED 100%);\n    position: relative;\n    z-index: 11;\n}\n\n.stepsBackground {\n    position: absolute;\n    top: 420px;\n    height: 980px;\n}\n\n.caruselBackground {\n    position: absolute;\n    top: 770px;\n    right: 0;\n}\n\n.stepsBlock {\n    margin-top: 115px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    padding: 0 60px;\n    position: relative;\n    z-index: 11;\n}\n\n.videoBlock {\n    width: 100%;\n    height: 100%;\n    border-radius: 32px;\n    text-align: center;\n    background-size: cover;\n}\n\n.react-player__preview {\n    background-size: cover !important;\n}\n\n.videoBlock img {\n    width: 80px;\n    /* margin-top: 228px; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
