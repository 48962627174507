// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anagramPdf_task {
    display: flex;
    justify-content: center;
    align-items: center;
}

.anagramPdf_taskHeader {
    display: flex;
}

.anagramPdf_task__item__question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6cm;
}

.anagramPdf_task__item__answer {
    width: 100%;
}

.anagramPdf_task__item__question img {
    width: 3cm;
}

.anagramPdf_task__item__answer__text, .anagramPdf_task__item__answer__square {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.anagramPdf_task__item__answer__text p {
    margin: 0 2mm;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/anagram/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".anagramPdf_task {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.anagramPdf_taskHeader {\n    display: flex;\n}\n\n.anagramPdf_task__item__question {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 6cm;\n}\n\n.anagramPdf_task__item__answer {\n    width: 100%;\n}\n\n.anagramPdf_task__item__question img {\n    width: 3cm;\n}\n\n.anagramPdf_task__item__answer__text, .anagramPdf_task__item__answer__square {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.anagramPdf_task__item__answer__text p {\n    margin: 0 2mm;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
