// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для спиннера */
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Высота контейнера спиннера */
}

.spinner__circle {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Светлая граница */
    border-left-color: #4CAF50; /* Цвет вращающейся границы */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Анимация вращения */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/spiner/Spinner.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,+BAA+B;AAClD;;AAEA;IACI,oCAAoC,EAAE,oBAAoB;IAC1D,0BAA0B,EAAE,6BAA6B;IACzD,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC,EAAE,sBAAsB;AAC9D;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Стили для спиннера */\n.spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100px; /* Высота контейнера спиннера */\n}\n\n.spinner__circle {\n    border: 4px solid rgba(0, 0, 0, 0.1); /* Светлая граница */\n    border-left-color: #4CAF50; /* Цвет вращающейся границы */\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 1s linear infinite; /* Анимация вращения */\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
