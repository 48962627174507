import React from "react";
import background from "assets/backgrounds/header.svg";
import purpuleArrow from "assets/purpuleArrow.svg";
import TaskCard from "components/components/taskCard/TaskCard";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; // Удален game_type, если не используется
import {
  fetchAllExercises,
  fetchAllExercisesConcat,
} from "store/actions/allExercises";
import { getQueryStringFromObject } from "functions/filter";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FETCH_ALLEXERCISES_CLEAR } from "store/actionTypes";
import "../media.css";
import "./media.css";
import "./style.css";
import { patternsGame } from "../../lists/list";

// Импортируем созданный компонент Spinner
import Spinner from "../../components/components/spiner/Spiner";
function Tasks({
  all_exercises,
  token,
  fetchAllExercises,
  fetchAllExercisesConcat,
  setVisible,
  PageAfterLogin,
  FETCH_ALLEXERCISES_CLEAR,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const temp = useRef();
  const [skip, setSkip] = useState(0);
  const [selectedType, setSelectedType] = useState("all");
  const [loading, setLoading] = useState(true); // Состояние для первоначальной загрузки
  const [isFetchingMore, setIsFetchingMore] = useState(false); // Состояние для загрузки дополнительных данных
  const [newTasks, setNewTasks] = useState([]);

  // Функция для инициализации загрузки задачек с сервера
  const startFunction = () => {
    setLoading(true); // Включаем состояние загрузки при первом запросе
    let obj = {};
    if (selectedType === "all") {
      obj.game_type = null;
    } else {
      obj.game_type = selectedType;
    }
    fetchAllExercises(0, 100, getQueryStringFromObject(JSON.stringify(obj))) // Загружаем с нуля, чтобы обновить данные
      .finally(() => setLoading(false)); // Выключаем состояние загрузки после завершения запроса
  };

  temp.current = startFunction;

  // useEffect(() => {
  //   const getNewTasks = async () => {
  //     try {
  //       const response = await fetch("http://62.113.97.189:8001/games");
  //       const data = await response.json();
  //       if (data) {
  //         setNewTasks(data);
  //       }
  //     } catch {
  //       console.log("ошибка");
  //       return;
  //     }
  //   };
  //   getNewTasks();
  // }, []);

  // Используем эффект для первоначальной загрузки задачек
  useEffect(() => {
    temp.current();
    window.scrollTo(0, 0);
    return () => {
      FETCH_ALLEXERCISES_CLEAR();
    };
  }, [FETCH_ALLEXERCISES_CLEAR]); // Добавляем зависимость

  // Используем эффект для перезагрузки задачек при изменении фильтров
  useEffect(() => {
    setLoading(true); // Включаем состояние загрузки при изменении фильтра
    let obj = {};
    if (selectedType === "all") {
      obj.game_type = null;
    } else {
      obj.game_type = selectedType;
    }
    fetchAllExercises(0, 100, getQueryStringFromObject(JSON.stringify(obj))) // Загружаем с нуля, чтобы обновить данные
      .finally(() => setLoading(false)); // Выключаем состояние загрузки после завершения запроса
  }, [selectedType, fetchAllExercises]); // Добавляем зависимости

  // Функция для загрузки дополнительных задачек при бесконечной прокрутке
  const fetchMoreData = () => {
    if (isFetchingMore) return; // Проверяем, не идет ли сейчас загрузка
    setIsFetchingMore(true); // Включаем состояние загрузки дополнительных данных
    let obj = {};
    if (selectedType === "all") {
      obj.game_type = null;
    } else {
      obj.game_type = selectedType;
    }
    fetchAllExercisesConcat(
      skip + 100,
      100,
      getQueryStringFromObject(JSON.stringify(obj))
    ) // Загружаем с учетом текущего skip
      .then(() => {
        setSkip(skip + 100); // Увеличиваем значение skip после успешной загрузки
      })
      .finally(() => setIsFetchingMore(false)); // Выключаем состояние загрузки дополнительных данных
  };

  return (
    <>
      <img src={background} className="mainMiniImg" alt="" />{" "}
      {/* Добавлено пустое значение для alt */}
      <div className="patternsBlock tasksBlock">
        {/* Контейнер для заголовка и фильтра */}
        <div className="tasks_header">
          <p className="patternsBlock_title">{t(`tasks.title`)}</p>
          {/* Фильтр находится непосредственно под заголовком */}
          <div className="tasks_filter">
            <label htmlFor="task-type-select" className="tasks_filter__label">
              Выберите тип задач:
            </label>
            <select
              id="task-type-select"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="tasks_filter__select"
            >
              <option value="all">Все</option>
              {patternsGame.map((pattern) => (
                <option key={pattern.type} value={pattern.type}>
                  {pattern.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Показ спиннера или контента в зависимости от состояния загрузки */}
        {loading ? (
          <div className="spinner-container">
            <Spinner /> {/* Показываем спиннер */}
          </div>
        ) : (
          <InfiniteScroll
            dataLength={all_exercises.length}
            next={fetchMoreData}
            hasMore={!isFetchingMore && all_exercises.length % 100 === 0} // Определяем, есть ли еще данные для загрузки
            loader={<Spinner />} // Показываем спиннер при подгрузке данных
          >
            <div className="patternsBlock_list tasks_list">
              {/* {newTasks.map((item) => {
                return <div onClick={() => navigate(`../task/${item.id}/watch?new=true`)}>{item.name}</div>;
              })} */}
              {all_exercises.map((item, key) => (
                <div key={`task_${key}`} className="tasks_list__item">
                  <TaskCard
                    item={item}
                    onClick={() => navigate(`../task/${item.id}/watch`)}
                    actionButton="Сохранить к себе"
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
      {token === null && (
        <p
          className="patternsBlock_button"
          onClick={() => {
            setVisible("login");
            PageAfterLogin("../create_task");
          }}
        >
          {t(`tasks.button`)}
          <img src={purpuleArrow} alt="purpuleArrow" />
        </p>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    all_exercises: state.all_exercises.all_exercises,
    token: state.login.token,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    fetchAllExercises: (skip, limit, filter) =>
      dispatch(fetchAllExercises(skip, limit, filter)),
    fetchAllExercisesConcat: (skip, limit, filter) =>
      dispatch(fetchAllExercisesConcat(skip, limit, filter)),
    setVisible: (text) => dispatch({ type: "setVisible", payload: text }),
    PageAfterLogin: (text) =>
      dispatch({ type: "PageAfterLogin", payload: text }),
    FETCH_ALLEXERCISES_CLEAR: () =>
      dispatch({ type: FETCH_ALLEXERCISES_CLEAR }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(Tasks);
