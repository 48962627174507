// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M09CbrGrfcADS9h49lqt {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.UnygHY5vf0H0UjLEY_Pv {
  display: flex;
  font-size: 1.4rem;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 360px;
  height: 220px;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}


@media (max-width: 600px) {
  .UnygHY5vf0H0UjLEY_Pv {
    font-size: 1.1rem;
  }
}





`, "",{"version":3,"sources":["webpack://./../exercise/src/components/modals/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0CAA0C;EAC1C,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,QAAQ;EACR,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iDAAiD;AACnD;;;AAGA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".modal {\n  border-radius: 10px;\n  background-color: rgba(255, 255, 255, 0.3);\n  z-index: 30;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  left: 0;\n}\n\n.resultsModal {\n  display: flex;\n  font-size: 1.4rem;\n  gap: 5px;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  background-color: white;\n  width: 360px;\n  height: 220px;\n  border-radius: 5px;\n  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);\n}\n\n\n@media (max-width: 600px) {\n  .resultsModal {\n    font-size: 1.1rem;\n  }\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export var modal = `M09CbrGrfcADS9h49lqt`;
export var resultsModal = `UnygHY5vf0H0UjLEY_Pv`;
export default ___CSS_LOADER_EXPORT___;
