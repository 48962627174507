// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .tasks_list {
        width: auto !important;
        justify-content: center !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/tasks/media.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,kCAAkC;IACtC;AACJ","sourcesContent":["@media (max-width: 575.98px) {\n    .tasks_list {\n        width: auto !important;\n        justify-content: center !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
