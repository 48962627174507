"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exerciseSchema = exports.taskSchema = void 0;
const zod_1 = require("zod");
exports.taskSchema = zod_1.z
    .object({
    type: zod_1.z.string(),
    index: zod_1.z.number(),
    indexName: zod_1.z.string(),
    checkResultType: zod_1.z.string(),
    // positions: z.array(
    //     .object({
    //       uid: z.string(),
    //       trueAnswer: z.string(),
    //     })
    //     .catchall(z.any())
    // ),
    positions: zod_1.z.record(zod_1.z.string(), zod_1.z.array(zod_1.z.object({ uid: zod_1.z.string(), trueAnswer: zod_1.z.any() }).catchall(zod_1.z.any()))),
    register: zod_1.z.boolean(),
    required: zod_1.z.boolean(),
    image: zod_1.z.string().optional(),
    question: zod_1.z.string().optional(),
    resultConfig: zod_1.z.any().optional(),
})
    .passthrough();
exports.exerciseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    tasks: zod_1.z.array(exports.taskSchema),
    creator: zod_1.z.string().optional(),
});
