// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findCoupleBg_img {
    max-width: 15px !important;
    border-radius: 5px;
}

.findCoupleBg_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/findCouple/style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".findCoupleBg_img {\n    max-width: 15px !important;\n    border-radius: 5px;\n}\n\n.findCoupleBg_item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
