"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatMilliseconds = formatMilliseconds;
function formatMilliseconds(ms) {
    const hours = Math.floor(ms / 3600000);
    ms -= hours * 3600000;
    const minutes = Math.floor(ms / 60000);
    ms -= minutes * 60000;
    const seconds = Math.floor(ms / 1000);
    const format = (num) => num.toString().padStart(2, "0");
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
}
