// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quizPdf_taskHeader {
    display: flex;
}

.quizPdf_task {
    display: flex;
    align-items: center;
}

.quizPdf_task__item__question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 4cm;
}

.quizPdf_task__item__question img {
    width: 3cm;
}

.quizPdf_task__item__answer {
    width: 10cm;
}

.quizrPdf_task__item__answer {
    display: flex;
    flex-direction: column;
    gap: 0.3cm;
}

.quizrPdf_task__item__answerItem {
    display: flex;
    align-items: center;
}

.quizrPdf_task__item__answerItem p {
    width: 8cm;
}

.quizPdf_task__item__answer__text {
    display: flex;
    align-items: center;
    gap: 0.5cm;
}

.quizPdf_task__item__answer__text img {
    width: 1.5cm;
    height: 1.5cm;
    border-radius: 0.5cm;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/quiz/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".quizPdf_taskHeader {\n    display: flex;\n}\n\n.quizPdf_task {\n    display: flex;\n    align-items: center;\n}\n\n.quizPdf_task__item__question {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 4cm;\n}\n\n.quizPdf_task__item__question img {\n    width: 3cm;\n}\n\n.quizPdf_task__item__answer {\n    width: 10cm;\n}\n\n.quizrPdf_task__item__answer {\n    display: flex;\n    flex-direction: column;\n    gap: 0.3cm;\n}\n\n.quizrPdf_task__item__answerItem {\n    display: flex;\n    align-items: center;\n}\n\n.quizrPdf_task__item__answerItem p {\n    width: 8cm;\n}\n\n.quizPdf_task__item__answer__text {\n    display: flex;\n    align-items: center;\n    gap: 0.5cm;\n}\n\n.quizPdf_task__item__answer__text img {\n    width: 1.5cm;\n    height: 1.5cm;\n    border-radius: 0.5cm;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
