// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.componentToPrint {
    border-radius: 10px;
    margin-bottom: 20px;
}

.pdfPage {
    font-family: 'Comic Sans MS';
    font-size: 14px;
}

.pdfPage_header {
    padding: 0 28px 20px 28px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000000;
}

.pdfPage_header p{
    width: 125px;
}

.pdfPage_content {
    padding: 8px 28px 28px 28px;
}

.pdfPage_content__description {
    text-align: center;
    line-height: 130%;
}

.pdfPage_content__description__title {
    font-weight: 700;
}

.pdfPage_content__tasks {
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".componentToPrint {\n    border-radius: 10px;\n    margin-bottom: 20px;\n}\n\n.pdfPage {\n    font-family: 'Comic Sans MS';\n    font-size: 14px;\n}\n\n.pdfPage_header {\n    padding: 0 28px 20px 28px;\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid #000000;\n}\n\n.pdfPage_header p{\n    width: 125px;\n}\n\n.pdfPage_content {\n    padding: 8px 28px 28px 28px;\n}\n\n.pdfPage_content__description {\n    text-align: center;\n    line-height: 130%;\n}\n\n.pdfPage_content__description__title {\n    font-weight: 700;\n}\n\n.pdfPage_content__tasks {\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
