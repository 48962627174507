import arrow from 'assets/arrow.svg';
import { connect } from 'react-redux';
import * as styles from './style.module.css';

const LetsGoRegister = ({
  setVisible
}) => {
  return (
    <div className={styles.letGoRegister}>
      <p className={styles.letGoRegister__title}>Зарегистрируйтесь</p>
      <p className={styles.letGoRegister__description}>Зарегистрируйтесь и получите бесплатный доступ ко всем ресурсам сайта на 5 дней</p>
      <button className={styles.letGoRegister__access_buttons_purple} onClick={() => setVisible('registration')}>
        Перейти к регистрации
        <img src={arrow} alt="purpuleArrow" />
      </button>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
      setVisible: (text) => dispatch({ type: 'changeType', payload: text }),
  }
}



export default connect(null, mapDispatchToProps)(LetsGoRegister)