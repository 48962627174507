// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patternsBlock_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.patternsBlock_header .patternsBlock_header__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
}

.patternsBlock_header .header_actionBlock__input {
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/views/patterns/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".patternsBlock_header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.patternsBlock_header .patternsBlock_header__title {\n    color: #FFFFFF;\n    font-weight: 700;\n    font-size: 48px;\n    line-height: 60px;\n}\n\n.patternsBlock_header .header_actionBlock__input {\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
