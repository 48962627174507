// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groups {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.groups_item {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 5.5cm;
    margin-bottom: 5mm;
}

.groups_item:nth-child(odd) {
    border-right: 0.5mm solid #000;
}

.groups_item:nth-child(even) {
    margin-left: 0.5mm;
}

.groups_item__linesBlock {
    width: 95%;
    height: 5cm;
    border: 0.5mm solid #000;
    border-radius: 1mm;
    padding: 0 3mm;
}

.groups_item__linesBlock__line {
    width: 100%;
    height: 6mm;
    border-bottom: 0.7mm solid #000;
}

.groupAnswer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.groupAnswer p {
    margin-left: 1mm;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/groupDivision/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,wBAAwB;IACxB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".groups {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.groups_item {\n    width: 48%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 5.5cm;\n    margin-bottom: 5mm;\n}\n\n.groups_item:nth-child(odd) {\n    border-right: 0.5mm solid #000;\n}\n\n.groups_item:nth-child(even) {\n    margin-left: 0.5mm;\n}\n\n.groups_item__linesBlock {\n    width: 95%;\n    height: 5cm;\n    border: 0.5mm solid #000;\n    border-radius: 1mm;\n    padding: 0 3mm;\n}\n\n.groups_item__linesBlock__line {\n    width: 100%;\n    height: 6mm;\n    border-bottom: 0.7mm solid #000;\n}\n\n.groupAnswer {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.groupAnswer p {\n    margin-left: 1mm;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
