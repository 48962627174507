// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insertLetterBg {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
    gap: 5px;
}

.insertLetterBg div {
    padding: 2px 5px!important;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 2px;
    background-position: center;
    margin-right: 2px;
    border-radius: 35px;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
    margin: 0px !important;
}

.draggableBlock.insertLetterBg {
    border-radius: 0 !important;
    height: 15px !important;
}

.insertLetterBg__questions_placeholderBlock {
    width: 100%;
    height: 80%;
    background-color: rgba(259, 259, 259, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    margin-top: auto;
}

.insertLetterBg__questions_placeholderBlock img {
    width: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/insertLetter/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,QAAQ;AACZ;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,qBAAqB;IACrB,kBAAkB;IAClB,2BAA2B;IAC3B,iBAAiB;IACjB,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,WAAW;IACX,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".insertLetterBg {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin: 10px 0;\n    gap: 5px;\n}\n\n.insertLetterBg div {\n    padding: 2px 5px!important;\n    background-repeat: no-repeat;\n    background-size: 100%;\n    margin-bottom: 2px;\n    background-position: center;\n    margin-right: 2px;\n    border-radius: 35px;\n    height: -moz-fit-content;\n    height: fit-content;\n    width: auto;\n    margin: 0px !important;\n}\n\n.draggableBlock.insertLetterBg {\n    border-radius: 0 !important;\n    height: 15px !important;\n}\n\n.insertLetterBg__questions_placeholderBlock {\n    width: 100%;\n    height: 80%;\n    background-color: rgba(259, 259, 259, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 0px;\n    margin-top: auto;\n}\n\n.insertLetterBg__questions_placeholderBlock img {\n    width: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
