// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quizBgAnswersImage {
    width: 25px;
    height: 25px;
    border-radius: 8px;
}

.quizBgAnswers {
    align-items: stretch;

}

.quizBgAnswers div {
    height: auto !important;
    align-items: center;
    justify-content: center ;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/quiz/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;;AAExB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;IACxB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".quizBgAnswersImage {\n    width: 25px;\n    height: 25px;\n    border-radius: 8px;\n}\n\n.quizBgAnswers {\n    align-items: stretch;\n\n}\n\n.quizBgAnswers div {\n    height: auto !important;\n    align-items: center;\n    justify-content: center ;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
