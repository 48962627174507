// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.joinBlock {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    padding: 48px;
    max-width: 620px;
    margin: auto;
}

.quickAuthorizationBlock {
    padding: 0 60px;
    position: relative;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/views/quickAuthorization/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,4CAA4C;IAC5C,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".joinBlock {\n    background: #FFFFFF;\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);\n    border-radius: 24px;\n    padding: 48px;\n    max-width: 620px;\n    margin: auto;\n}\n\n.quickAuthorizationBlock {\n    padding: 0 60px;\n    position: relative;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
