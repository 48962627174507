// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spaceMathematicsPdf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1cm;
}

.spaceMathematicsPdf_taskHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 150px;
}

.spaceMathematicsPdf_taskHeader img {
    width: 100%;
    height: 100%;
}

.spaceMathematicsPdf_taskHeader p {
    position: absolute;
}

.spaceMathematicsPdf_task {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    height: 2cm;
}

.spaceMathematicsPdf_task__answer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/spaceMathematics/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".spaceMathematicsPdf {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1cm;\n}\n\n.spaceMathematicsPdf_taskHeader {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 300px;\n    height: 150px;\n}\n\n.spaceMathematicsPdf_taskHeader img {\n    width: 100%;\n    height: 100%;\n}\n\n.spaceMathematicsPdf_taskHeader p {\n    position: absolute;\n}\n\n.spaceMathematicsPdf_task {\n    display: flex;\n    width: 50%;\n    flex-wrap: wrap;\n    height: 2cm;\n}\n\n.spaceMathematicsPdf_task__answer {\n    width: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
