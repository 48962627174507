// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CeFRV7ckjNiCwQmq9TD0 {
  text-align: center;
  color: var(--darkBlue);
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.yfoX9X5BaVyI6G_cYdug {
}

@media (max-width: 600px) {
  .yfoX9X5BaVyI6G_cYdug {
    font-size: 0.6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/TableResults.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;AACA;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".title {\n  text-align: center;\n  color: var(--darkBlue);\n  font-size: 1.1rem;\n  font-weight: 600;\n  margin-bottom: 0.7rem;\n}\n\n.table {\n}\n\n@media (max-width: 600px) {\n  .table {\n    font-size: 0.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `CeFRV7ckjNiCwQmq9TD0`;
export var table = `yfoX9X5BaVyI6G_cYdug`;
export default ___CSS_LOADER_EXPORT___;
