// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registrationPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 65vh;
    background-color: #f0f2f5;
}

.registrationPage_container {
    width: 90%;
    max-width: 500px; /* Увеличиваем максимальную ширину */
    padding: 3rem; /* Используем rem для отступов */
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.registrationPage_title {
    font-size: 1.5rem; /* Используем rem для шрифта */
    font-weight: 700;
    margin-bottom: 2rem; /* Используем rem для отступа */
    text-align: center;
}

.registrationForm {
    width: 100%;
}

.registrationForm_item {
    margin-bottom: 1.5rem; /* Используем rem для отступов между элементами формы */
}

.registrationForm_button {
    width: 100%;
    background-color: #7C3AED;
    color: #ffffff;
    border-radius: 4px;
    padding: 0.75rem 1rem; /* Используем rem для внутренних отступов */
    font-size: 1rem; /* Используем rem для размера шрифта */
}
`, "",{"version":3,"sources":["webpack://./src/views/registrationPage/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,gBAAgB,EAAE,oCAAoC;IACtD,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB;IACzB,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,iBAAiB,EAAE,8BAA8B;IACjD,gBAAgB;IAChB,mBAAmB,EAAE,+BAA+B;IACpD,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB,EAAE,uDAAuD;AAClF;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,qBAAqB,EAAE,2CAA2C;IAClE,eAAe,EAAE,sCAAsC;AAC3D","sourcesContent":[".registrationPage {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 65vh;\n    background-color: #f0f2f5;\n}\n\n.registrationPage_container {\n    width: 90%;\n    max-width: 500px; /* Увеличиваем максимальную ширину */\n    padding: 3rem; /* Используем rem для отступов */\n    background-color: #ffffff;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}\n\n.registrationPage_title {\n    font-size: 1.5rem; /* Используем rem для шрифта */\n    font-weight: 700;\n    margin-bottom: 2rem; /* Используем rem для отступа */\n    text-align: center;\n}\n\n.registrationForm {\n    width: 100%;\n}\n\n.registrationForm_item {\n    margin-bottom: 1.5rem; /* Используем rem для отступов между элементами формы */\n}\n\n.registrationForm_button {\n    width: 100%;\n    background-color: #7C3AED;\n    color: #ffffff;\n    border-radius: 4px;\n    padding: 0.75rem 1rem; /* Используем rem для внутренних отступов */\n    font-size: 1rem; /* Используем rem для размера шрифта */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
