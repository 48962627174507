// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectInviteLink {
    display: flex;
    align-items: center;
}

.selectInviteLink_link {
    padding: 8px 12px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #374151;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    margin-right: 10px;
    overflow-x: hidden;
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selectInviteLink_link img {
    margin-right: 8px;
}

.createGroup {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    cursor: pointer;
}

.createGroup img {
    margin-right: 5px;
}

.filterTablePlayers {
    display: flex;
    margin-bottom: 15px;
}

.filterTablePlayers_filter {
    color: #7C3AED;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background: #EADFFC;
    border-radius: 10px;
    padding: 0px 8px;
    cursor: pointer;
    margin-right: 8px;
}

.filterTablePlayers_filter.active {
    color: #FFFFFF;
    background: #7C3AED;
}
`, "",{"version":3,"sources":["webpack://./src/views/results/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".selectInviteLink {\n    display: flex;\n    align-items: center;\n}\n\n.selectInviteLink_link {\n    padding: 8px 12px;\n    background: #FFFFFF;\n    border: 1px solid #D1D5DB;\n    box-sizing: border-box;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    color: #374151;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    cursor: pointer;\n    margin-right: 10px;\n    overflow-x: hidden;\n    width: 300px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.selectInviteLink_link img {\n    margin-right: 8px;\n}\n\n.createGroup {\n    display: flex;\n    align-items: center;\n    padding: 2px 8px;\n    cursor: pointer;\n}\n\n.createGroup img {\n    margin-right: 5px;\n}\n\n.filterTablePlayers {\n    display: flex;\n    margin-bottom: 15px;\n}\n\n.filterTablePlayers_filter {\n    color: #7C3AED;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    background: #EADFFC;\n    border-radius: 10px;\n    padding: 0px 8px;\n    cursor: pointer;\n    margin-right: 8px;\n}\n\n.filterTablePlayers_filter.active {\n    color: #FFFFFF;\n    background: #7C3AED;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
