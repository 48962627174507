"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeChecker = void 0;
const results_1 = require("exercise/types/results");
const results_2 = require("exercise/types/results");
const exerciseModal_1 = require("exercise/models/exerciseModal");
const resolve_1 = require("exercise/types/resolve");
class TypeChecker {
    constructor(checkType, userAnswer, trueAnswer, required, register, resultConfig) {
        this.checkType = checkType;
        this.userAnswer = userAnswer;
        this.trueAnswer = trueAnswer;
        this.required = required;
        this.register = register;
        this.resultConfig = resultConfig;
        if (resultConfig.type) {
            switch (resultConfig.type) {
                case resolve_1.ResultConfigTypes.MANY_MANY:
                    this.checkType = resolve_1.ResultConfigTypes.MANY_MANY;
                    break;
            }
        }
        if (this.userAnswer == exerciseModal_1.NO_ANSWER) {
            this.userAnswer = "";
        }
        if (!register &&
            typeof this.userAnswer == "string" &&
            typeof this.trueAnswer == "string") {
            this.userAnswer = this.userAnswer.toLowerCase();
            this.trueAnswer = this.trueAnswer.toLowerCase();
        }
    }
    rightType() {
        const result = this.userAnswer == this.trueAnswer ? 1 : 0;
        const mark = result ? results_2.ResultMark.GOOD : results_2.ResultMark.BAD;
        return { user: result, all: 1, mark };
    }
    notRequired() {
        return { user: 1, all: 1, mark: results_2.ResultMark.MEDIUM };
    }
    manyMany() {
        if (!Array.isArray(this.trueAnswer)) {
            return { user: 0, all: 0, mark: results_2.ResultMark.BAD };
        }
        let result = 0;
        for (let value of this.userAnswer) {
            if (this.trueAnswer.includes(value)) {
                result += 1;
            }
        }
        return {
            user: result,
            all: this.trueAnswer.length,
            mark: this.trueAnswer.length == result ? results_2.ResultMark.GOOD : results_2.ResultMark.MEDIUM,
        };
    }
    lettersType() {
        let mark = results_2.ResultMark.MEDIUM;
        let result = 0;
        const answer = this.userAnswer ? this.userAnswer.toString() : "";
        const trueAnswer = this.trueAnswer.toString();
        for (let i = 0; i < trueAnswer.length; i++) {
            if (trueAnswer[i] == answer[i]) {
                result++;
            }
        }
        if (result == 0) {
            mark = results_2.ResultMark.BAD;
        }
        else if (result == trueAnswer.length) {
            mark = results_2.ResultMark.GOOD;
        }
        console.log({ user: result, all: trueAnswer.length, mark });
        return { user: result, all: trueAnswer.length, mark };
    }
    calculate() {
        switch (this.checkType) {
            case results_1.CheckResultType.RIGHT:
                return this.rightType();
            case results_1.CheckResultType.LETTERS:
                return this.lettersType();
            case results_1.CheckResultType.NOT_REQUIRED:
                return this.notRequired();
            case resolve_1.ResultConfigTypes.MANY_MANY:
                return this.manyMany();
            default:
                return this.rightType();
        }
    }
}
exports.TypeChecker = TypeChecker;
