import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import ru from "../../public/languages/ru/translation.json"

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["ru"],
    fallbackLng: "ru",
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "subdomain"],
      caches: ["cookie"],
    },
    resources: {
      ru: {
        translation: ru,
      },
    },
    lng: 'ru',
    // backend: {
    //     loadPath: '/languages/{{lng}}/translation.json',
    // },
  });

export default i18next;
