// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupDivionBlock {
    font-family: 'Comic Sans MS';
    font-weight: 700;
    font-size: 16px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 20px;
}

.groupDivionBlock_questions__column {
    text-align: center;
    width: 30%;
}

.groupDivionBlock_questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white;
    height: 100%;
    gap: 10px;
}

.groupDivionBlock_questions__column__title {
    padding: 21px 35px;
    background-color: #e15f41;
    border-radius: 20px;
}

.groupDivionBlock_questions__column__item {
    background-color: #f3a683;
    width: 90%;
    padding: 5px 0;
    border-radius: 15px;
    margin: 5px auto;
}

.groupDivionBlock_answers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.groupDivionBlock_answers .groupDivionBlock_answers__item{
    background-color: #F7BB61;
    border-radius: 20px;
    padding: 8px;
    min-width: 160px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/games/groupDivision/style.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,cAAc;IACd,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".groupDivionBlock {\n    font-family: 'Comic Sans MS';\n    font-weight: 700;\n    font-size: 16px;\n    margin-top: 28px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n    gap: 20px;\n}\n\n.groupDivionBlock_questions__column {\n    text-align: center;\n    width: 30%;\n}\n\n.groupDivionBlock_questions {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    color: white;\n    height: 100%;\n    gap: 10px;\n}\n\n.groupDivionBlock_questions__column__title {\n    padding: 21px 35px;\n    background-color: #e15f41;\n    border-radius: 20px;\n}\n\n.groupDivionBlock_questions__column__item {\n    background-color: #f3a683;\n    width: 90%;\n    padding: 5px 0;\n    border-radius: 15px;\n    margin: 5px auto;\n}\n\n.groupDivionBlock_answers {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.groupDivionBlock_answers .groupDivionBlock_answers__item{\n    background-color: #F7BB61;\n    border-radius: 20px;\n    padding: 8px;\n    min-width: 160px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
