import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import burgerMenu from 'assets/burgerMenu.svg';
import cross from 'assets/close_grey.svg';
import greyCursor from 'assets/greyCursor.svg';
import en from 'assets/language/en.png';
import ru from 'assets/language/ru.png';
import logo from 'assets/logo.svg';
import purpuleCheck from 'assets/purpuleCheck.svg';
import { header, header_path, header_user } from 'lists/list';

import { useEffect, useState } from 'react';
import DrawerBlock from '../dropdown/DrawerBlock';
import './style.css';

function Header(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sampleLocation = useLocation();
  const location = useLocation();
  const [activeDrawer, setActiveDrawer] = useState(null);

  const menu = (
    <div className='languageMenu'>
      <img src={ru} alt='ru' onClick={() => i18n.changeLanguage('ru')} />
      <br />
      <img src={en} alt='en' onClick={() => i18n.changeLanguage('en')} />
    </div>
  );

  useEffect(() => {
    if (activeDrawer) setActiveDrawer(false);
  }, [location]);

  const profileMenu = (
    <div className='languageMenu'>
      {header_user.map((item, key) => {
        return (
          <Link to={item.link} className='linkFooter' key={key}>
            <p>{t(`header.${item.title}`)}</p>
          </Link>
        );
      })}
      <p
        style={{ color: 'red', cursor: 'pointer' }}
        onClick={() => {
          props.EXIT();
          props.FETCH_USERME_EXIT();
        }}
      >
        {t(`header.exit`)}
      </p>
    </div>
  );

  const handleLogoClick = () => {
    window.location.href = 'https://superkid.online';
  };

  let path = header_path.indexOf(sampleLocation.pathname);
  let result = sampleLocation.pathname.split('/');
  result = header_path.indexOf(result[1]);

  return (
    <div
      className={`header ${path === -1 ? result === -1 && 'background' : null}`}
      style={{ background: sampleLocation.pathname === '/' ? '#3AC2ED' : 'transparent' }}
    >
      <img src={logo} alt='logo' className='logo' onClick={handleLogoClick} />
      <div className='header_links'>
        {header.map((item, key) => {
          return (
            <Link
              to={item.link}
              className={
                path === -1
                  ? 'linkFooter'
                  : header_path.indexOf('/profile') !== -1
                  ? 'linkFooter'
                  : 'link'
              }
              key={key}
            >
              <p>{t(`header.${item.title}`)}</p>
            </Link>
          );
        })}
      </div>
      <div className='header_actionBlock'>
        <div className='searchBlock'>
          <Input
            className='header_actionBlock__input'
            placeholder={t(`header.search_placeholder`)}
            prefix={<SearchOutlined />}
            onChange={(e) => props.onSearch(e.target.value)}
            value={props.search}
          />
          {props.search.length > 0 && (
            <div className='searchTypeBlock'>
              <div
                className={`searchTypeBlock_item ${props.search_type === 'tasks' && 'active'}`}
                onClick={() => props.onSelectSearchType('tasks')}
              >
                <p>{t(`header.search.tasks`)}</p>
                <img src={purpuleCheck} alt='purpuleCheck' />
              </div>
              <div
                className={`searchTypeBlock_item ${props.search_type === 'users' && 'active'}`}
                onClick={() => props.onSelectSearchType('users')}
              >
                <p>{t(`header.search.users`)}</p>
                <img src={purpuleCheck} alt='purpuleCheck' />
              </div>
              <div
                className={`searchTypeBlock_item ${props.search_type === 'hashtags' && 'active'}`}
                onClick={() => props.onSelectSearchType('hashtags')}
              >
                <p>{t(`header.search.hashtags`)}</p>
                <img src={purpuleCheck} alt='purpuleCheck' />
              </div>
            </div>
          )}
        </div>
        {props.token === null && (
          <button
            className='header_actionBlock__buttonEntry'
            onClick={() => {
              props.setVisible('login');
              props.PageAfterLogin('../myTask');
            }}
          >
            {t(`header.entry_button`)}
          </button>
        )}
        {props.token === null && (
          <button
            className='header_actionBlock__button__registration'
            onClick={() => {
              props.setVisible('registration');
              props.PageAfterLogin('../profile/edit');
            }}
          >
            {t(`header.registration_button`)}
          </button>
        )}
        {props.token && (
          <button className='header_actionBlock__create' onClick={() => navigate(`../create_task`)}>
            {t(`header.create_button`)}
          </button>
        )}
        {props.token && (
          <button className='header_actionBlock__update' onClick={() => navigate(`../myTask`)}>
            Мои задания
          </button>
        )}
        {props.token && props.userMe && (
          <Dropdown overlay={profileMenu} trigger={['click']}>
            <div className='profileMenu'>
              {props.userMe.photo !== null ? (
                <img src={props.userMe.photo} alt='avatar' />
              ) : (
                <UserOutlined />
              )}
              <p>
                {props.userMe.first_name} {props.userMe.last_name}
              </p>
              <img src={greyCursor} alt='greyCursor' />
            </div>
          </Dropdown>
        )}
        <img
          src={burgerMenu}
          alt='burgerMenu'
          className='header_burger_menu'
          onClick={() => setActiveDrawer(true)}
        />
        <DrawerBlock active={activeDrawer} onClose={() => setActiveDrawer(false)}>
          <div className='header_burger_menu__drawer'>
            <div className='header_burger_menu__drawer__topBlock'>
              <div className='header_burger_menu__drawer__topBlock__header'>
                <Link to='/'>
                  <img
                    src={logo}
                    alt='logo'
                    className='header_burger_menu__drawer__topBlock__header__logo'
                  />
                </Link>
                <img
                  src={cross}
                  alt='cross'
                  className='header_burger_menu__drawer__topBlock__header__close'
                  onClick={() => setActiveDrawer(false)}
                />
              </div>
              <div className='header_burger_menu__drawer__topBlock__content'>
                {header.map((item, key) => {
                  return (
                    <Link
                      to={item.link}
                      className={
                        path === -1
                          ? 'linkFooter'
                          : header_path.indexOf('/profile') !== -1
                          ? 'linkFooter'
                          : 'link'
                      }
                      key={key}
                    >
                      <p>{t(`header.${item.title}`)}</p>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className='header_burger_menu__drawer__bottomBlock'>
              {props.token === null && (
                <button
                  className='header_actionBlock__buttonEntry__drawer'
                  onClick={() => {
                    props.setVisible('login');
                    props.PageAfterLogin('../myTask');
                  }}
                >
                  {t(`header.entry_button`)}
                </button>
              )}
              {props.token === null && (
                <button
                  className='header_actionBlock__button__registration__drawer'
                  onClick={() => {
                    props.setVisible('registration');
                    props.PageAfterLogin('../profile/edit');
                  }}
                >
                  {t(`header.registration_button`)}
                </button>
              )}
              {props.token && (
                <button
                  className='header_actionBlock__create__drawer'
                  onClick={() => navigate(`../create_task`)}
                >
                  {t(`header.create_button`)}
                </button>
              )}
              {props.token && (
                <button
                  className='header_actionBlock__update__drawer'
                  onClick={() => navigate(`../myTask`)}
                >
                  Мои задания
                </button>
              )}
            </div>
          </div>
        </DrawerBlock>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    search_type: state.search.search_type,
    search: state.search.search,
    userMe: state.userMe.userMe,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: (text) => dispatch({ type: 'setVisible', payload: text }),
    onSearch: (text) => dispatch({ type: 'onSearch', payload: text }),
    EXIT: () => dispatch({ type: 'EXIT' }),
    FETCH_USERME_EXIT: () => dispatch({ type: 'FETCH_USERME_EXIT' }),
    onSelectSearchType: (text) => dispatch({ type: 'onSelectSearchType', payload: text }),
    PageAfterLogin: (text) => dispatch({ type: 'PageAfterLogin', payload: text }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
