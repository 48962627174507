// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yesOrNot_pdf {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    /* justify-content: center; */
    gap: 0.6cm;
}

.yesOrNot_pdf_item {
    width: 4cm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3cm;
    /* border: 1px solid #000000; */
}

.yesOrNot_pdf_item__circles {
    display: flex;
}

.yesOrNot_pdf_item__circles__item {
    display: flex;
    align-items: center;
}

.yesOrNot_pdf_item__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.yesOrNot_pdf_item__content p {
    text-align: center;
}


.yesOrNot_pdf_item__content img {
    width: 100%;
    max-width: 150px;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/yesOrNot/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".yesOrNot_pdf {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-items: flex-end;\n    /* justify-content: center; */\n    gap: 0.6cm;\n}\n\n.yesOrNot_pdf_item {\n    width: 4cm;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 0.3cm;\n    /* border: 1px solid #000000; */\n}\n\n.yesOrNot_pdf_item__circles {\n    display: flex;\n}\n\n.yesOrNot_pdf_item__circles__item {\n    display: flex;\n    align-items: center;\n}\n\n.yesOrNot_pdf_item__content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.yesOrNot_pdf_item__content p {\n    text-align: center;\n}\n\n\n.yesOrNot_pdf_item__content img {\n    width: 100%;\n    max-width: 150px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
