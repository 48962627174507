// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardBgYesOrNot {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-basis: 450px;
    position: relative;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
}

.cardBgYesOrNot_answerBlock {
    background: #A4D6EF;
    border-radius: 12px;
    padding: 5px;
    width: 60px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardBgYesOrNot_answerBlock__title {
    font-weight: 700;
    font-size: 10px;
    line-height: 32px;
}

.cardBgYesOrNot_answerBlock__plusBlock {
    width: 50px;
    height: 35px;
    border: 2px solid #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardBgYesOrNot_answerBlock__plusBlock img {
    width: 15px;
}

.cardBgYesOrNot_questionBlock {
    padding: 10px;
    background: #FFFFFF;
    border: 2px solid black;
    border-radius: 6px;
    width: 100px;
    text-align: center;
}

.cardBgYesOrNot_questionBlock img {
    width: 100%;
}

.cardBgYesOrNot_questionBlock p{
    font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/yesOrNot/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".cardBgYesOrNot {\n    display: flex;\n    flex-direction: row;\n    height: 100%;\n    flex-basis: 450px;\n    position: relative;\n    z-index: 10;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.cardBgYesOrNot_answerBlock {\n    background: #A4D6EF;\n    border-radius: 12px;\n    padding: 5px;\n    width: 60px;\n    height: 80px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.cardBgYesOrNot_answerBlock__title {\n    font-weight: 700;\n    font-size: 10px;\n    line-height: 32px;\n}\n\n.cardBgYesOrNot_answerBlock__plusBlock {\n    width: 50px;\n    height: 35px;\n    border: 2px solid #FFFFFF;\n    border-radius: 6px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.cardBgYesOrNot_answerBlock__plusBlock img {\n    width: 15px;\n}\n\n.cardBgYesOrNot_questionBlock {\n    padding: 10px;\n    background: #FFFFFF;\n    border: 2px solid black;\n    border-radius: 6px;\n    width: 100px;\n    text-align: center;\n}\n\n.cardBgYesOrNot_questionBlock img {\n    width: 100%;\n}\n\n.cardBgYesOrNot_questionBlock p{\n    font-size: 9px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
