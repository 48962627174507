// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

input,
textarea {
  all: unset;
  background-color: white;
}

.pointer {
  cursor: pointer;
}

.customScroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.customScroll::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/index.css"],"names":[],"mappings":";;AAEA;;EAEE,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["\n\ninput,\ntextarea {\n  all: unset;\n  background-color: white;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.customScroll::-webkit-scrollbar {\n  width: 12px;\n  height: 12px;\n}\n\n.customScroll::-webkit-scrollbar-track {\n  background: #e0e0e0;\n  border-radius: 10px;\n}\n\n.customScroll::-webkit-scrollbar-thumb {\n  background: #ffffff;\n  border-radius: 10px;\n  border: 2px solid #e0e0e0;\n}\n\n.customScroll::-webkit-scrollbar-thumb:hover {\n  background: #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
