// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patternInfoBlock_header__share {
    background: #EDE9FE;
    border-radius: 8px;
    padding: 8px 12px;
    color: #7C3AED;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.patternInfoBlock_header__share img {
    margin-right: 10px;
}

.taskInfoBlock {
    z-index: 10 !important;
    margin-bottom: 30px;
}

.taskInfo_actions__hashtags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

}

.taskInfo_actions__hashtags__item {
    cursor: pointer;
    padding: 2px 10px;
    background: #EDE9FE;
    border-radius: 5px;
    color: #7C3AED;
}`, "",{"version":3,"sources":["webpack://./src/views/taskInfo/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,QAAQ;;AAEZ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".patternInfoBlock_header__share {\n    background: #EDE9FE;\n    border-radius: 8px;\n    padding: 8px 12px;\n    color: #7C3AED;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    cursor: pointer;\n}\n\n.patternInfoBlock_header__share img {\n    margin-right: 10px;\n}\n\n.taskInfoBlock {\n    z-index: 10 !important;\n    margin-bottom: 30px;\n}\n\n.taskInfo_actions__hashtags {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    gap: 5px;\n\n}\n\n.taskInfo_actions__hashtags__item {\n    cursor: pointer;\n    padding: 2px 10px;\n    background: #EDE9FE;\n    border-radius: 5px;\n    color: #7C3AED;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
