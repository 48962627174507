// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createTask_stepsBlock {
    background: #FBFBFC;
    border: 1px solid #EDF0F3;
    border-radius: 16px;
    padding: 4px 12px;
    margin-bottom: 32px;
    overflow-x: scroll;
}

.createTask_stepsBlock::-webkit-scrollbar {
    height: 2px;
}

.createTask_stepsBlock::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid grey;
}

.createTask_patterns {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.createTask_themes {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)) !important;
    gap: 15px;
}

.createTask_themes__item {
    display: flex;
    justify-content: center;
}

.createTask_themes__item .leaderBoard_themes__item {
    margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/createTask/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,uEAAuE;IACvE,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".createTask_stepsBlock {\n    background: #FBFBFC;\n    border: 1px solid #EDF0F3;\n    border-radius: 16px;\n    padding: 4px 12px;\n    margin-bottom: 32px;\n    overflow-x: scroll;\n}\n\n.createTask_stepsBlock::-webkit-scrollbar {\n    height: 2px;\n}\n\n.createTask_stepsBlock::-webkit-scrollbar-thumb {\n    border-radius: 20px;\n    border: 1px solid grey;\n}\n\n.createTask_patterns {\n    position: relative;\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.createTask_themes {\n    display: grid !important;\n    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)) !important;\n    gap: 15px;\n}\n\n.createTask_themes__item {\n    display: flex;\n    justify-content: center;\n}\n\n.createTask_themes__item .leaderBoard_themes__item {\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
