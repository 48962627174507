// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Leavesflake {
    display: inline-block;
    width: 70px;
    height: 70px;
    color: #FFFAFA;
    opacity: 0;
    margin: 0;
    padding: 0;
    animation: fall 15s infinite;
}

.Leavesflake img {
    position: absolute;
    animation: upDown 1.5s alternate infinite ease-in-out;
    width: 90px;
}

@keyframes fall {
    0% {
        opacity: 0;
    }

    3% {
        opacity: 0.9;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        transform: translate(0, 100vh);
        opacity: 0;
    }
}

@keyframes upDown {
    to {
        transform: translateX(50px) rotate(50deg);
    }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.leavesAnimation {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/animation/autumn/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,UAAU;IACV,SAAS;IACT,UAAU;IACV,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,qDAAqD;IACrD,WAAW;AACf;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,8BAA8B;QAC9B,UAAU;IACd;AACJ;;AAEA;IACI;QACI,yCAAyC;IAC7C;AACJ;;AAEA;IACI,KAAK,uBAAuB,EAAE;GAC/B,MAAM,uBAAuB,EAAE;GAC/B,MAAM,uBAAuB,EAAE;GAC/B,MAAM,wBAAwB,EAAE;EACjC,OAAO,uBAAuB,EAAE;AAClC;;AAEA;IACI,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".Leavesflake {\n    display: inline-block;\n    width: 70px;\n    height: 70px;\n    color: #FFFAFA;\n    opacity: 0;\n    margin: 0;\n    padding: 0;\n    animation: fall 15s infinite;\n}\n\n.Leavesflake img {\n    position: absolute;\n    animation: upDown 1.5s alternate infinite ease-in-out;\n    width: 90px;\n}\n\n@keyframes fall {\n    0% {\n        opacity: 0;\n    }\n\n    3% {\n        opacity: 0.9;\n    }\n\n    90% {\n        opacity: 0.9;\n    }\n\n    100% {\n        transform: translate(0, 100vh);\n        opacity: 0;\n    }\n}\n\n@keyframes upDown {\n    to {\n        transform: translateX(50px) rotate(50deg);\n    }\n}\n\n@keyframes wiggle {\n    0% { transform: rotate(0deg); }\n   80% { transform: rotate(0deg); }\n   85% { transform: rotate(5deg); }\n   95% { transform: rotate(-5deg); }\n  100% { transform: rotate(0deg); }\n}\n\n.leavesAnimation {\n    background-repeat: no-repeat;\n    background-size: cover;\n    display: flex;\n    justify-content: space-between;\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 25px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
