// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Up56NfxQhDGKBFowWn_8 {
  outline: 2px solid #cd2222 !important;
}

.xlXcLq59RbTmamJuceZa {
  font-size: 1.2rem;
  padding: 0.3rem 0.2rem;
  border-radius: 6px;
  width: 100%;
  color: black;
}

.xlXcLq59RbTmamJuceZa::placeholder {
  color: #b1b1b1;
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/inputs/InputValidator.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".bad {\n  outline: 2px solid #cd2222 !important;\n}\n\n.inputValidator {\n  font-size: 1.2rem;\n  padding: 0.3rem 0.2rem;\n  border-radius: 6px;\n  width: 100%;\n  color: black;\n}\n\n.inputValidator::placeholder {\n  color: #b1b1b1;\n}\n"],"sourceRoot":""}]);
// Exports
export var bad = `Up56NfxQhDGKBFowWn_8`;
export var inputValidator = `xlXcLq59RbTmamJuceZa`;
export default ___CSS_LOADER_EXPORT___;
