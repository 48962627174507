// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YJRNpykd2vL3696ZkJ1q {
  font-family: "Rubick";
  color: var(--darkBlue);
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
}

.ya310A58crAmygIOwU87 {
  width: 100%;
  border-radius: 4px;
  text-align: center;
  color: var(--darkBlue);
  font-family: "Rubick";
  padding: 0.2rem 0.1rem;
  font-size: 1.1rem;
  outline: 0.5px solid #b1b1b1;
  border: none;
}

.puMmMIGQPJGVhIm9Ge8_ {
  background-color: #7c3aed;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  padding: 0.3rem 0.1rem;
  margin-top: 0.4rem;
}

`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/Editor/EditorDopPanel.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".title {\n  font-family: \"Rubick\";\n  color: var(--darkBlue);\n  font-weight: bold;\n  font-size: 1.1rem;\n  text-align: center;\n}\n\n.select {\n  width: 100%;\n  border-radius: 4px;\n  text-align: center;\n  color: var(--darkBlue);\n  font-family: \"Rubick\";\n  padding: 0.2rem 0.1rem;\n  font-size: 1.1rem;\n  outline: 0.5px solid #b1b1b1;\n  border: none;\n}\n\n.chapter {\n  background-color: #7c3aed;\n  border-radius: 4px;\n  color: white;\n  font-weight: 600;\n  font-size: 1rem;\n  text-align: center;\n  padding: 0.3rem 0.1rem;\n  margin-top: 0.4rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var title = `YJRNpykd2vL3696ZkJ1q`;
export var select = `ya310A58crAmygIOwU87`;
export var chapter = `puMmMIGQPJGVhIm9Ge8_`;
export default ___CSS_LOADER_EXPORT___;
