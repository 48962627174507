// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/games/spaceMathematics/star.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/games/spaceMathematics/starShip.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spaceMathematicsBg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
}

.starsBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.leftStars_item, .rightStars_item {
    width: 55px;
    height: 35px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftStars_item:nth-child(1),
.leftStars_item:nth-child(3) {
    margin-left: 20px;
}

.rightStars_item:nth-child(1),
.rightStars_item:nth-child(3) {
    margin-left: -30px;
}

.leftStars_item p, .rightStars_item p {
    background: #FFFFFF;
    font-size: 8px;
    min-width: 15px;
    border-radius: 5px;
}

.startShip {
    width: 100%;
    height: 100px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: 100% 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startShip p{
    background: #FFFFFF;
    font-size: 6px;
    min-width: 15px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/spaceMathematics/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yDAA+D;IAC/D,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yDAAmE;IACnE,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".spaceMathematicsBg {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 150px;\n}\n\n.starsBlock {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n}\n\n.leftStars_item, .rightStars_item {\n    width: 55px;\n    height: 35px;\n    background-image: url('assets/games/spaceMathematics/star.svg');\n    background-size: 100% 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.leftStars_item:nth-child(1),\n.leftStars_item:nth-child(3) {\n    margin-left: 20px;\n}\n\n.rightStars_item:nth-child(1),\n.rightStars_item:nth-child(3) {\n    margin-left: -30px;\n}\n\n.leftStars_item p, .rightStars_item p {\n    background: #FFFFFF;\n    font-size: 8px;\n    min-width: 15px;\n    border-radius: 5px;\n}\n\n.startShip {\n    width: 100%;\n    height: 100px;\n    background-image: url('assets/games/spaceMathematics/starShip.svg');\n    background-size: 100% 100%;\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.startShip p{\n    background: #FFFFFF;\n    font-size: 6px;\n    min-width: 15px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
