"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultMark = exports.CheckResultType = void 0;
var CheckResultType;
(function (CheckResultType) {
    CheckResultType["RIGHT"] = "right";
    CheckResultType["LETTERS"] = "letters";
    CheckResultType["NOT_REQUIRED"] = "notRequired";
})(CheckResultType || (exports.CheckResultType = CheckResultType = {}));
var ResultMark;
(function (ResultMark) {
    ResultMark["GOOD"] = "good";
    ResultMark["BAD"] = "bad";
    ResultMark["MEDIUM"] = "medium";
})(ResultMark || (exports.ResultMark = ResultMark = {}));
