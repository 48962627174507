import { useState, useEffect, useRef } from 'react';
import './style.css';

const createKeyBoard = () => {
    const keyBoard = [[], []];
    const specialSymbol = ['-', '+', '=', '÷', '×'];

    // Добавляем цифры
    for (let i = 48; i < 58; i++) {
        keyBoard[0].push(String.fromCharCode(i));
    }
    // Добавляем специальные символы
    for (let i = 0; i < specialSymbol.length; i++) {
        keyBoard[0].push(specialSymbol[i]);
    }
    // Добавляем русские буквы
    for (let i = 1040; i < 1046; i++) {
        keyBoard[1].push(String.fromCharCode(i));
    }
    keyBoard[1].push(String.fromCharCode(1025));
    for (let i = 1046; i < 1072; i++) {
        keyBoard[1].push(String.fromCharCode(i));
    }

    return keyBoard;
};

function WriteExtendedAnswerBg({ game }) {
    const [keyBoard, setKeyBoard] = useState([]);
    const [answer, setAnswer] = useState(''); // Одно поле ввода для длинного ответа
    const temp = useRef();

    const startFunction = () => {
        setKeyBoard(createKeyBoard());
    };

    temp.current = startFunction;

    useEffect(() => {
        temp.current();
    }, []);

    // Обработка нажатий на клавиши виртуальной клавиатуры
    const handleKeyboardClick = (value) => {
        setAnswer((prev) => prev + value);
    };

    return (
        <div className='exercisesCardBg_content'>
            <div className='exercisesCardBg_content__card'>
                {game[0].photo !== null
                    ? <img src={game[0].photo} alt="game" />
                    : <p>{game[0].text}</p>
                }
            </div>
            <div className='extendedAnswerBg'>
                <textarea
                    className="writeAnswerBlock_question__answerItem"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    placeholder="Введите ваш развернутый ответ"
                    style={{ width: '100%', height: '100px', padding: '10px' }}
                />
            </div>
            <div>
                {keyBoard.map((row, key) => (
                    <div className="writeAnswerBlock_keyboard" key={key}>
                        {row.map((value, index) => (
                            <div
                                className="keyboardItem writeAnswerBgCard"
                                key={index}
                                onClick={() => handleKeyboardClick(value)}
                            >
                                {value}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WriteExtendedAnswerBg;
