import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSearchExercises } from "store/actions/searchExercises";
import { fetchUsers } from "store/actions/searchUsers";

import TaskCard from "components/components/taskCard/TaskCard";

import UserCard from "components/components/userCard/UserCard";
import { useState } from "react";
import { SAVE_ACTIVE_HASHTAGS_SUCCESS } from "store/actionTypes";
import { fetchSearchHashtags } from "store/actions/hashtags";
import "./style.css";
import { instance } from "API/api";

function Search({
  search,
  search_type,
  users,
  search_exercises,
  search_exercises_by_hashtags,
  fetchUsers,
  fetchSearchExercises,
  fetchSearchHashtags,
  search_hashtags,
  SAVE_ACTIVE_HASHTAGS_SUCCESS,
  active_hashtags,
  removeSearch,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeHashtags, setActiveHashtags] = useState([]);
  const [newTasks, setNewTasks] = useState([]);

  useEffect(() => {
    fetchUsers("first_name_partial", search);
    fetchSearchExercises("search_name", search);
    fetchSearchHashtags("search_name", search);
  }, [search.length]);

  const getNewTasks = async () => {
      try {
        const response = await instance.post(
          `${process.env.SERVER_URL}/games/hashtags`,
          active_hashtags.length ? active_hashtags.map((el) => el.id) : [0]
        );
        if (Array.isArray(response.data)) {
          setNewTasks(response.data);
          console.log(response.data);
        }
      } catch (err) {
        setNewTasks([]);
        console.log(err);
      }
  };

  useEffect(() => {
    fetchSearchExercises(
      "hashtag_ids",
      active_hashtags.map((el) => el.id)
    );
    getNewTasks();
  }, [active_hashtags]);

  const onClickHashtag = (item) => {
    if (activeHashtags.indexOf(item.id) === -1) {
      setActiveHashtags([...activeHashtags, item.id]);
    } else {
      setActiveHashtags(activeHashtags.filter((el) => el !== item.id));
    }

    if (!active_hashtags.find((el) => el.id === item.id)) {
      SAVE_ACTIVE_HASHTAGS_SUCCESS([...active_hashtags, item]);
    } else {
      SAVE_ACTIVE_HASHTAGS_SUCCESS(
        active_hashtags.filter((el) => el.id !== item.id)
      );
    }
  };

  return (
    <div className="patternsBlock" style={{ marginTop: "40px" }}>
      <p className="searchBlock_title">{t(`search.${search_type}`)}</p>
      {search_type === "hashtags" ? (
        <>
          <div className="hashtags_list">
            {active_hashtags.map((el) => {
              if (!search_hashtags.find((item) => item.id === el.id)) {
                return (
                  <div
                    key={`search_hashtags_${el.id}`}
                    className={`search_list__hashtagsItem search_list__hashtagsItem__active`}
                    onClick={() => onClickHashtag(el)}
                  >
                    #{el.name}
                  </div>
                );
              }
            })}
            {search_hashtags.map((item) => (
              <div
                key={`search_hashtags_${item.id}`}
                className={`search_list__hashtagsItem ${
                  active_hashtags.find((el) => el.id === item.id)
                    ? "search_list__hashtagsItem__active"
                    : ""
                }`}
                onClick={() => onClickHashtag(item)}
              >
                #{item.name}
              </div>
            ))}
          </div>
          <div className="patternsBlock_list search_list">
            {search_exercises_by_hashtags.map((item, key) => (
              <div key={`search_task_${item.id}`} className="search_list__item">
                <TaskCard
                  item={item}
                  onClick={() => navigate(`../task/${item.id}/watch`)}
                  actionButton="Сохранить к себе"
                />
              </div>
            ))}
            {newTasks.map((element) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-center position-relative"
                  onClick={() => {
                    removeSearch();
                    navigate(`../task/${element.id}/watch?new=true`);
                  }}
                  style={{
                    minHeight: "286px",
                    backgroundColor: "#28B1FF",
                    borderRadius: "5px",
                    fontWeigth: "bold",
                    color: "white",
                    cursor: "pointer",
                    textAlign: "center",
                    fontFamily: "DelaGothicOne",
                  }}
                >
                  Задание
                  <br />
                  {element.name}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="patternsBlock_list search_list">
          {search_type === "tasks" &&
            search_exercises.map((item, key) => (
              <div key={`search_task_${item.id}`} className="search_list__item">
                <TaskCard
                  item={item}
                  onClick={() => navigate(`../task/${item.id}/watch`)}
                  actionButton="Сохранить к себе"
                />
              </div>
            ))}
          {search_type === "users" &&
            users.map((item, key) => (
              <div key={`search_user_${item.id}`} className="search_list__item">
                <UserCard user={item} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    search: state.search.search,
    search_type: state.search.search_type,
    users: state.users.users,
    search_exercises: state.search_exercises.search_exercises,
    search_exercises_by_hashtags:
      state.search_exercises.search_exercises_by_hashtags,
    search_hashtags: state.search_hashtags.hashtags,
    active_hashtags: state.search_hashtags.active_hashtags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (type, text) => dispatch(fetchUsers(type, text)),
    fetchSearchExercises: (type, text) =>
      dispatch(fetchSearchExercises(type, text)),
    fetchSearchHashtags: (type, text) =>
      dispatch(fetchSearchHashtags(type, text)),
    removeSearch: (text) => dispatch({ type: "onSearch", payload: "" }),
    SAVE_ACTIVE_HASHTAGS_SUCCESS: (hashtags) =>
      dispatch({
        type: SAVE_ACTIVE_HASHTAGS_SUCCESS,
        hashtags,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
