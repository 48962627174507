"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textOrImageValidate = exports.textOrImageAll = exports.simpleAnswerValidate = exports.simpleAnswerAll = exports.decartValidate = exports.decartAll = void 0;
const nanoid_1 = require("nanoid");
const validators_1 = require("../validators/validators");
const resolve_1 = require("exercise/types/resolve");
const decartAll = (position, stepInfo) => {
    const { rows, cols, trueAnswers } = position;
    const { row: rowInfo, col: colInfo } = stepInfo;
    let positions = [];
    const uniqueRows = rows.filter((value, index, self) => index === self.findIndex((t) => t.name === value.name));
    const uniqueCols = cols.filter((value, index, self) => index === self.findIndex((t) => t.name === value.name));
    uniqueRows.forEach((r, indexRow) => {
        uniqueCols.forEach((c, indexCol) => {
            const position = {
                uid: `${indexRow}${indexCol}${(0, nanoid_1.nanoid)()}`,
                [rowInfo.label]: r.name,
                [colInfo.label]: c.name,
                trueAnswer: trueAnswers[`${c.id}${r.id}`],
                questionLabel: `${r.name}-${c.name}`,
                trueAnswerLabel: trueAnswers[`${c.id}${r.id}`],
            };
            positions.push(position);
        });
    });
    return {
        positions,
        resultConfig: {},
    };
};
exports.decartAll = decartAll;
const decartValidate = (position, stepInfo) => {
    let result = [];
    const { rows, cols, trueAnswers } = position;
    const { row: rowInfo, col: colInfo, trueAnswer: trueAnswerInfo } = stepInfo;
    rows.forEach((element) => {
        for (let key of rowInfo.validate) {
            const problem = validators_1.allValidators[key](element.name);
            if (problem) {
                result.push(element.id);
                break;
            }
        }
    });
    cols.forEach((element) => {
        for (let key of colInfo.validate) {
            const problem = validators_1.allValidators[key](element.name);
            if (problem) {
                result.push(element.id);
                break;
            }
        }
    });
    for (let answerKey in trueAnswers) {
        for (let key of rowInfo.validate) {
            const problem = validators_1.allValidators[key](trueAnswers[answerKey]);
            if (problem) {
                result.push(answerKey);
                break;
            }
        }
    }
    return result;
};
exports.decartValidate = decartValidate;
const simpleAnswerAll = (position, stepInfo) => {
    const { answer, trueAnswer } = position;
    const { answer: answerInfo, trueAnswer: trueAnswerInfo, id } = stepInfo;
    const positions = [
        {
            uid: `${id}${(0, nanoid_1.nanoid)()}`,
            [answerInfo.label]: answer.name,
            trueAnswer: trueAnswer.name,
            questionLabel: answer.name,
            trueAnswerLabel: trueAnswer.name,
        },
    ];
    return {
        positions,
        resultConfig: {},
    };
};
exports.simpleAnswerAll = simpleAnswerAll;
const simpleAnswerValidate = (position, stepInfo) => {
    const { answer, trueAnswer } = position;
    const { answer: answerInfo, trueAnswer: trueAnswerInfo, id } = stepInfo;
    let result = [];
    for (let key of answerInfo.validate) {
        const problem = validators_1.allValidators[key](answer.name);
        if (problem) {
            result.push(answer.id);
            break;
        }
    }
    for (let key of trueAnswerInfo.validate) {
        const problem = validators_1.allValidators[key](trueAnswer.name);
        if (problem) {
            result.push(trueAnswer.id);
            break;
        }
    }
    return result;
};
exports.simpleAnswerValidate = simpleAnswerValidate;
const textOrImageAll = (position, stepInfo) => {
    const { answers, trueAnswer } = position;
    let realAnswers = [...answers];
    let realTrueAnswer = [];
    realAnswers = answers.map((element, i) => {
        if (!element.name && element.url) {
            return Object.assign(Object.assign({}, element), { name: `Изображие ${i + 1}`, onlyImage: true });
        }
        return element;
    });
    realAnswers.forEach((element) => {
        if (trueAnswer.includes(element.id)) {
            realTrueAnswer.push(element.name);
        }
    });
    const positions = [
        {
            answers: realAnswers,
            uid: (0, nanoid_1.nanoid)(),
            trueAnswer: realTrueAnswer,
            trueAnswerLabel: realTrueAnswer.join(", "),
        },
    ];
    return {
        positions,
        resultConfig: {
            type: resolve_1.ResultConfigTypes.MANY_MANY,
        },
    };
};
exports.textOrImageAll = textOrImageAll;
const textOrImageValidate = (position, stepInfo) => {
    let result = [];
    const { answers, trueAnswer, type } = position;
    answers.forEach((element) => {
        if (!element.url && !element.name) {
            result.push(element.id);
        }
    });
    return result;
};
exports.textOrImageValidate = textOrImageValidate;
