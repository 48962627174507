import { api_user } from 'API/users';
import { api_subscription } from 'API/subscriptions'; // Добавьте этот импорт
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserMe, fetchUserMeSubscription } from 'store/actions/userMe';

import { fetchLogin } from 'store/actions/login';

import './style.css'; // Подключаем новый файл стилей

function RegistrationPage({
                              link,
                              fetchLogin,
                              fetchUserMe,
                              fetchUserMeSubscription
                          }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onFinish = async (values) => {
        if (values.password === values.yet_password) {
            await api_user.CreateUser(values, t(`modal.registration.registartion_error`))
                .then(res => {
                    if (res.status === 200) {
                        let data = {
                            'username': values.email,
                            'password': values.password,
                        };
                        fetchLogin(data)
                            .then(async (res) => {
                                if (res !== "Incorrect username or password" && res !== 'Error: Network Error') {
                                    await api_subscription.FreeTrial();
                                    fetchUserMeSubscription();
                                    fetchUserMe();
                                    navigate('/myTask'); // Перенаправляем пользователя на страницу /myTask после успешной регистрации
                                }
                            });
                    }
                });
        } else {
            message.error(t(`modal.registration.password_match`));
        }
    };

    return (
        <div className="registrationPage">
            <div className="registrationPage_container">
                <p className="registrationPage_title">{t(`modal.registration.title`)}</p>
                <Form
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="registrationForm"
                >
                    <div className="fioBlock">
                        <Form.Item
                            label={t(`modal.registration.name`)}
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: t(`modal.registration.name_error`),
                                },
                            ]}
                        >
                            <Input className="formInput" />
                        </Form.Item>
                        <Form.Item
                            label={t(`modal.registration.surname`)}
                            name="last_name"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input className="formInput" />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label={t(`modal.registration.email`)}
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t(`modal.registration.email_error`),
                            },
                        ]}
                    >
                        <Input className="formInput" />
                    </Form.Item>
                    <Form.Item
                        label={t(`modal.registration.password`)}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.registration.password_error`),
                            },
                        ]}
                    >
                        <Input.Password className="formInput" />
                    </Form.Item>
                    <Form.Item
                        label={t(`modal.registration.yet_password`)}
                        name="yet_password"
                        rules={[
                            {
                                required: true,
                                message: t(`modal.registration.yet_password_error`),
                            },
                        ]}
                    >
                        <Input.Password className="formInput" />
                    </Form.Item>
                    <Form.Item>
                        <Button className="registrationForm_button" htmlType="submit">{t(`modal.registration.button`)}</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        link: state.login.link,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchUserMe: () => dispatch(fetchUserMe()),
        fetchLogin: (data) => dispatch(fetchLogin(data)),
        fetchUserMeSubscription: () => dispatch(fetchUserMeSubscription()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
