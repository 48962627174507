// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainInstructionBlock {
    margin-top: 80px;
    position: relative;
}

.mainInstructionBlock_content {
    background: #FFFFFF;
}

.mainInstructionBlock_content__items {
    width: 100%;
    max-width: 1181px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.mainInstructionBlock_conePurpleGlossy {
    position: absolute;
    top: 250px;
    left: 112px;
    z-index: 1;
}

.mainInstructionBlock_roundCubeBlueGlossy {
    position: absolute;
    bottom: 0px;
    right: 112px;
    z-index: 1;
}

@media (max-width: 575.98px) {
    .mainInstructionBlock_roundCubeBlueGlossy {
        position: absolute;
        bottom: 200px;
        right: 0;
        z-index: 13;
        width: 80px;
    }

    .mainInstructionBlock_conePurpleGlossy {
        position: absolute;
        top: 280px;
        left: 0;
        z-index: 13;
        width: 80px;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/main/mainBlocks/instruction.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,QAAQ;QACR,WAAW;QACX,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,UAAU;QACV,OAAO;QACP,WAAW;QACX,WAAW;IACf;AACJ","sourcesContent":[".mainInstructionBlock {\n    margin-top: 80px;\n    position: relative;\n}\n\n.mainInstructionBlock_content {\n    background: #FFFFFF;\n}\n\n.mainInstructionBlock_content__items {\n    width: 100%;\n    max-width: 1181px;\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.mainInstructionBlock_conePurpleGlossy {\n    position: absolute;\n    top: 250px;\n    left: 112px;\n    z-index: 1;\n}\n\n.mainInstructionBlock_roundCubeBlueGlossy {\n    position: absolute;\n    bottom: 0px;\n    right: 112px;\n    z-index: 1;\n}\n\n@media (max-width: 575.98px) {\n    .mainInstructionBlock_roundCubeBlueGlossy {\n        position: absolute;\n        bottom: 200px;\n        right: 0;\n        z-index: 13;\n        width: 80px;\n    }\n\n    .mainInstructionBlock_conePurpleGlossy {\n        position: absolute;\n        top: 280px;\n        left: 0;\n        z-index: 13;\n        width: 80px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
