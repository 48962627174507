// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matchElementBgAnswers {
    height: auto !important;
    min-height: 0 !important;
    width: auto !important;
    padding-top: 2px;
    padding-bottom: 2px;
    align-items: center;
}

.matchElementBgAnswers img{
    max-width: 15px !important;
}

.matchElementBgAnswers p {
    line-height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/matchElements/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;IACxB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".matchElementBgAnswers {\n    height: auto !important;\n    min-height: 0 !important;\n    width: auto !important;\n    padding-top: 2px;\n    padding-bottom: 2px;\n    align-items: center;\n}\n\n.matchElementBgAnswers img{\n    max-width: 15px !important;\n}\n\n.matchElementBgAnswers p {\n    line-height: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
