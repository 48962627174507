// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fBRAelI0mis4VwfaxsT_ { 
    width: 260px;
    height: 178px;
    border-radius: 5px;
    cursor: pointer;
    background-size: cover;
    background-position: center
}`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/Steps/Theme.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB;AACJ","sourcesContent":[".themeIcon { \n    width: 260px;\n    height: 178px;\n    border-radius: 5px;\n    cursor: pointer;\n    background-size: cover;\n    background-position: center\n}"],"sourceRoot":""}]);
// Exports
export var themeIcon = `fBRAelI0mis4VwfaxsT_`;
export default ___CSS_LOADER_EXPORT___;
