// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oDjTKQsXvKNLXQVmJrTP {
    width: 100%;
}

.oDjTKQsXvKNLXQVmJrTP .iw2i54JHWXqKQw6aFBGl {
    margin-bottom: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/games/checkYourself.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".form__columnInputs {\n    width: 100%;\n}\n\n.form__columnInputs .form__columnInputs_item {\n    margin-bottom: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
export var form__columnInputs = `oDjTKQsXvKNLXQVmJrTP`;
export var form__columnInputs_item = `iw2i54JHWXqKQw6aFBGl`;
export default ___CSS_LOADER_EXPORT___;
