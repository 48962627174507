// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A3WtGPd1DYflC35iJdlA {
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: "Rubick";
  font-size: 1.8rem;
  color: "black";
}

.toE1hjOP0HLU_tsPwaPI {
  font-size: 1.2rem;
  border-radius: 6px;
  width: 100%;
  min-height: 96px;
  text-align: center;
  font-family: "Rubick";
  font-size: 1.8rem;
  color: black;
}

.toE1hjOP0HLU_tsPwaPI::placeholder {
  color: #b1b1b1;
}


@media (max-width: 788px) {
  .A3WtGPd1DYflC35iJdlA {
    font-size: 1.3rem;
  }
}

@media (max-width: 440px) {
  .toE1hjOP0HLU_tsPwaPI {
    font-size: 1.3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Task/ExtendedAnswer/ExtendedAnswer.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".question {\n  margin-bottom: 1rem;\n  font-weight: bold;\n  font-family: \"Rubick\";\n  font-size: 1.8rem;\n  color: \"black\";\n}\n\n.inputAnswer {\n  font-size: 1.2rem;\n  border-radius: 6px;\n  width: 100%;\n  min-height: 96px;\n  text-align: center;\n  font-family: \"Rubick\";\n  font-size: 1.8rem;\n  color: black;\n}\n\n.inputAnswer::placeholder {\n  color: #b1b1b1;\n}\n\n\n@media (max-width: 788px) {\n  .question {\n    font-size: 1.3rem;\n  }\n}\n\n@media (max-width: 440px) {\n  .inputAnswer {\n    font-size: 1.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var question = `A3WtGPd1DYflC35iJdlA`;
export var inputAnswer = `toE1hjOP0HLU_tsPwaPI`;
export default ___CSS_LOADER_EXPORT___;
