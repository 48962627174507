// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceCard {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}

.serviceCard_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.serviceCard_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.serviceCard_description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.serviceCard_image {
    border-radius: 10px;
    width: 340px;
    height: 230px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -30px;
}

@media (max-width: 575.98px) {
    .serviceCard_image {
        width: 280px;
        height: 176.78px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/components/serviceCard/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,UAAU;AACd;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".serviceCard {\n    width: 100%;\n    height: 100%;\n    background: #FFFFFF;\n    border-radius: 10px;\n    text-align: center;\n    padding: 20px;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    cursor: pointer;\n}\n\n.serviceCard_text {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n}\n\n.serviceCard_title {\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 28px;\n}\n\n.serviceCard_description {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 19px;\n}\n\n.serviceCard_image {\n    border-radius: 10px;\n    width: 340px;\n    height: 230px;\n    position: absolute;\n    left: 0;\n    right: 0;\n    margin: auto;\n    top: -30px;\n}\n\n@media (max-width: 575.98px) {\n    .serviceCard_image {\n        width: 280px;\n        height: 176.78px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
