import { useState } from "react"
import ReactPlayer from "react-player"

import playCircule from 'assets/PlayCircle.svg'
import preview from 'assets/preview.jpg'

export const VideoBlock = () => {
    const [playVideo, setPlayVideo] = useState(false)

    return (
        <>
            <div className='videoBlock' style={{ backgroundImage: !playVideo && 'url(assets/preview.jpg)' }} id="main_video_block">
                <ReactPlayer
                    className='react-player'
                    url='https://lk.superkid.online/videos/main_video.mp4'
                    width='100%'
                    height='100%'
                    controls={true}
                    light={preview}
                    playing={true}
                    playIcon={<img src={playCircule} alt="playCircule" onClick={() => setPlayVideo(!playVideo)} />}
                />
            </div>
        </>
    )
}