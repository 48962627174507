// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .groupDivionFormBlock_item {
        width: 100%;
    }

    .quezeAnswersBlock {
        flex-direction: column;
    }

    .leftAnswer {
        margin-bottom: 0 !important;
    }

    .rightAnswer {
        margin-bottom: 10px !important;
    }

    .form_matchElementquestionBlock,
    .yesOrNotTasksBlock,
    .insertLetterSecond_answerBlock {
        flex-direction: column;
    }

    .yesOrNotTasksBlock {
        gap: 15px;
    }

    .yesOrNotTasksBlock_item {
        width: 100% !important;
    }

    .find_couple_questions {
        gap: 10px;
    }

    .form_matchElementquestionBlock .form_matchElementquestionBlock__item {
        margin: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .groupDivionFormBlock_item {
        width: 100%;
    }

    .quezeAnswersBlock,
    .yesOrNotTasksBlock {
        flex-direction: column;
    }

    .yesOrNotTasksBlock {
        gap: 15px;
    }

    .yesOrNotTasksBlock_item {
        width: 100% !important;
    }

    .leftAnswer {
        margin-bottom: 0 !important;
    }

    .rightAnswer {
        margin-bottom: 10px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}`, "",{"version":3,"sources":["webpack://./src/components/forms/games/media.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;IACf;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,2BAA2B;IAC/B;;IAEA;QACI,8BAA8B;IAClC;;IAEA;;;QAGI,sBAAsB;IAC1B;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,SAAS;IACb;AACJ;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;;QAEI,sBAAsB;IAC1B;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,2BAA2B;IAC/B;;IAEA;QACI,8BAA8B;IAClC;AACJ;;AAEA,qDAAqD;;AAErD,sDAAsD;;AAEtD,4BAA4B","sourcesContent":["@media (max-width: 575.98px) {\n    .groupDivionFormBlock_item {\n        width: 100%;\n    }\n\n    .quezeAnswersBlock {\n        flex-direction: column;\n    }\n\n    .leftAnswer {\n        margin-bottom: 0 !important;\n    }\n\n    .rightAnswer {\n        margin-bottom: 10px !important;\n    }\n\n    .form_matchElementquestionBlock,\n    .yesOrNotTasksBlock,\n    .insertLetterSecond_answerBlock {\n        flex-direction: column;\n    }\n\n    .yesOrNotTasksBlock {\n        gap: 15px;\n    }\n\n    .yesOrNotTasksBlock_item {\n        width: 100% !important;\n    }\n\n    .find_couple_questions {\n        gap: 10px;\n    }\n\n    .form_matchElementquestionBlock .form_matchElementquestionBlock__item {\n        margin: 0;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .groupDivionFormBlock_item {\n        width: 100%;\n    }\n\n    .quezeAnswersBlock,\n    .yesOrNotTasksBlock {\n        flex-direction: column;\n    }\n\n    .yesOrNotTasksBlock {\n        gap: 15px;\n    }\n\n    .yesOrNotTasksBlock_item {\n        width: 100% !important;\n    }\n\n    .leftAnswer {\n        margin-bottom: 0 !important;\n    }\n\n    .rightAnswer {\n        margin-bottom: 10px !important;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {}\n\n@media (min-width: 992px) and (max-width: 1199.98px) {}\n\n@media (min-width: 1200px) {}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
