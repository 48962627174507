// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instructionCard_img {
    width: 100px;
    height: 100px;
}

.instructionCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 380px;
    position: relative;
    z-index: 12;
    background-color: white;
    border-radius: 10px;
}

.instructionCard_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
}

.instructionCard_title {
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
}

.instructionCard_description {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/components/components/instructionCard/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".instructionCard_img {\n    width: 100px;\n    height: 100px;\n}\n\n.instructionCard {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    gap: 20px;\n    width: 380px;\n    position: relative;\n    z-index: 12;\n    background-color: white;\n    border-radius: 10px;\n}\n\n.instructionCard_text {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    gap: 12px;\n}\n\n.instructionCard_title {\n    font-weight: 500;\n    font-size: 28px;\n    line-height: 33px;\n}\n\n.instructionCard_description {\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 21px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
