// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vTSDIR0kKMS31feFJ3FS {
  gap: 15px;
  padding: 24px 32px;
}

.cuQfSn1R3t8Qdj8EAZ2z {
  width: 100%;
  position: absolute;
}

@media (max-width: 600px) {
  .cuQfSn1R3t8Qdj8EAZ2z {
    width: 200%;
    left: -20%;
  }
}

@media (max-width: 500px) {
  .vTSDIR0kKMS31feFJ3FS {
    padding: 12px 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/resolveMode/ExerciseResovleMode.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,UAAU;EACZ;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".resolveMode {\n  gap: 15px;\n  padding: 24px 32px;\n}\n\n.theme {\n  width: 100%;\n  position: absolute;\n}\n\n@media (max-width: 600px) {\n  .theme {\n    width: 200%;\n    left: -20%;\n  }\n}\n\n@media (max-width: 500px) {\n  .resolveMode {\n    padding: 12px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var resolveMode = `vTSDIR0kKMS31feFJ3FS`;
export var theme = `cuQfSn1R3t8Qdj8EAZ2z`;
export default ___CSS_LOADER_EXPORT___;
