// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customModal_result  {
    width: 1060px !important;
}

.customModal .ant-modal-content {
    padding: 48px;
    background: #FFFFFF;
    border-radius: 24px;
}

.customModal .ant-modal-content .ant-modal-footer {
    display: none;
}

.customModal .ant-modal-content .ant-modal-body {
    padding: 0;
}

@media (max-width: 575.98px) {
    .customModal .ant-modal-content {
        padding: 28px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/components/customModal/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".customModal_result  {\n    width: 1060px !important;\n}\n\n.customModal .ant-modal-content {\n    padding: 48px;\n    background: #FFFFFF;\n    border-radius: 24px;\n}\n\n.customModal .ant-modal-content .ant-modal-footer {\n    display: none;\n}\n\n.customModal .ant-modal-content .ant-modal-body {\n    padding: 0;\n}\n\n@media (max-width: 575.98px) {\n    .customModal .ant-modal-content {\n        padding: 28px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
