// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h8Y4zEZ5G88GxAjfah9_ {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: "AnonymousPro";
  background-size: cover;
  background-position: center;
  background-color: #5dc5ff;
}

.dsnhqRZnFNneZ2SehYqi {
  height: 700px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.qk7n9q58V5qzWF3CCTdp {
  font-family: "DelaGothicOne";
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
}

.QmQG0nHhtw6uykPmFptV {
  position: absolute;
  border-radius: 10px;
}

.SPoxKDRlwOuIJPrc3dz2 {
  width: 100%;
  height: 100%;
}

.sVlMwqAsrD2OB5z11Ogf { 
  cursor: pointer; 
  font-size: 1rem;
  font-weight: 500;
  color: #7C3AED;
  padding: 0.27rem 0.32rem;
  border-radius: 5px;
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: #EDE9FE;
}


.Zn5SRRPqayJPBuOv4hdA { 
  min-height: 200px;
}

@media (min-height: 1300px) {
  .dsnhqRZnFNneZ2SehYqi {
    height: 900px;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Exercise/Exercise.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,2BAA2B;EAC3B,sBAAsB;EACtB,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,wBAAwB;EACxB,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;;AAGA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".exerciseContainer {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  font-family: \"AnonymousPro\";\n  background-size: cover;\n  background-position: center;\n  background-color: #5dc5ff;\n}\n\n.exercise {\n  height: 700px;\n  overflow: hidden;\n  position: relative;\n  border-radius: 10px;\n}\n\n.exerciseName {\n  font-family: \"DelaGothicOne\";\n  font-size: 1.3rem;\n  margin-bottom: 0.4rem;\n}\n\n.themeAnimation {\n  position: absolute;\n  border-radius: 10px;\n}\n\n.svgTheme {\n  width: 100%;\n  height: 100%;\n}\n\n.footerButton { \n  cursor: pointer; \n  font-size: 1rem;\n  font-weight: 500;\n  color: #7C3AED;\n  padding: 0.27rem 0.32rem;\n  border-radius: 5px;\n  gap: 8px;\n  display: flex;\n  align-items: center;\n  background-color: #EDE9FE;\n}\n\n\n.tableResult { \n  min-height: 200px;\n}\n\n@media (min-height: 1300px) {\n  .exercise {\n    height: 900px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var exerciseContainer = `h8Y4zEZ5G88GxAjfah9_`;
export var exercise = `dsnhqRZnFNneZ2SehYqi`;
export var exerciseName = `qk7n9q58V5qzWF3CCTdp`;
export var themeAnimation = `QmQG0nHhtw6uykPmFptV`;
export var svgTheme = `SPoxKDRlwOuIJPrc3dz2`;
export var footerButton = `sVlMwqAsrD2OB5z11Ogf`;
export var tableResult = `Zn5SRRPqayJPBuOv4hdA`;
export default ___CSS_LOADER_EXPORT___;
