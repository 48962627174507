// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/backgrounds/games/winter.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snowflake {
	display: inline-block;
	width: 5%;
	color: #FFFAFA;
	opacity: 0;
	font-size: 420px;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 97vh);
		opacity: 0;
	}
}

.snowAnimation {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/animation/snow/style.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,SAAS;CACT,cAAc;CACd,UAAU;CACV,gBAAgB;CAChB,SAAS;CACT,UAAU;CACV,mCAAmC;AACpC;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,YAAY;CACb;;CAEA;EACC,YAAY;CACb;CACA;EACC,6BAA6B;EAC7B,UAAU;CACX;AACD;;AAEA;IACI,yDAA4D;IAC5D,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;CAChB,8BAA8B;CAC9B,SAAS;CACT,UAAU;CACV,gBAAgB;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".Snowflake {\n\tdisplay: inline-block;\n\twidth: 5%;\n\tcolor: #FFFAFA;\n\topacity: 0;\n\tfont-size: 420px;\n\tmargin: 0;\n\tpadding: 0;\n\tanimation: fall 16s linear infinite;\n}\n\n@keyframes fall {\n\t0% {\n\t\topacity: 0;\n\t}\n\t3% {\n\t\topacity: 0.9;\n\t}\n\n\t90% {\n\t\topacity: 0.9;\n\t}\n\t100% {\n\t\ttransform: translate(0, 97vh);\n\t\topacity: 0;\n\t}\n}\n\n.snowAnimation {\n    background-image: url('assets/backgrounds/games/winter.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    display: flex;\n\tjustify-content: space-between;\n\tmargin: 0;\n\tpadding: 0;\n\toverflow: hidden;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 25px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
