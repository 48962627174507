// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insertLetterAnswers div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.insertLetterAnswers div p {
    padding: 20px;
    border-radius: 35px;
}

.insertLetterQuestion .draggableBlock {
    justify-content: center;
    position: relative;
}

.insertLetterQuestion {
    overflow-y: scroll;
}

.insertLetterQuestion::-webkit-scrollbar {
    height: 0;
}

.questions__placeholderBlock {
    width: 100%;
    height: 80%;
    background-color: rgba(259, 259, 259, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-top: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.questions__placeholderBlock img {
    width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/games/insertLetter/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;IACX,WAAW;IACX,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,WAAW;AACf","sourcesContent":[".insertLetterAnswers div {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.insertLetterAnswers div p {\n    padding: 20px;\n    border-radius: 35px;\n}\n\n.insertLetterQuestion .draggableBlock {\n    justify-content: center;\n    position: relative;\n}\n\n.insertLetterQuestion {\n    overflow-y: scroll;\n}\n\n.insertLetterQuestion::-webkit-scrollbar {\n    height: 0;\n}\n\n.questions__placeholderBlock {\n    width: 100%;\n    height: 80%;\n    background-color: rgba(259, 259, 259, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 2px;\n    margin-top: auto;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.questions__placeholderBlock img {\n    width: 30px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
