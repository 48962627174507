// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerBlock {
    position: fixed;
    top: 0;
   
    background: #FFFFFF;
    box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.15);
    height: 100vh;
    z-index: 115;
    padding: 24px 0;
    right: -100%;
}

.drawerBlock.notActive {
    animation: ani2 1.2s forwards;
}

.drawerBlock.active {
    right: 0;
    animation: ani 1s forwards;
}

.drawerBlock_bg {
    background: rgba(14, 20, 18, 0.4);;
    backdrop-filter: blur(2px);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
}

@keyframes ani {
    0% {
        right: -100%
    }

    100% {
        right: 0;
    }
}

@keyframes ani2 {
    0% {
        right: 0
    }

    100% {
        right: -100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/components/dropdown/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;;IAEN,mBAAmB;IACnB,6CAA6C;IAC7C,aAAa;IACb,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,QAAQ;IACR,0BAA0B;AAC9B;;AAEA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,eAAe;IACf,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,YAAY;AAChB;;AAEA;IACI;QACI;IACJ;;IAEA;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI;IACJ;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".drawerBlock {\n    position: fixed;\n    top: 0;\n   \n    background: #FFFFFF;\n    box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.15);\n    height: 100vh;\n    z-index: 115;\n    padding: 24px 0;\n    right: -100%;\n}\n\n.drawerBlock.notActive {\n    animation: ani2 1.2s forwards;\n}\n\n.drawerBlock.active {\n    right: 0;\n    animation: ani 1s forwards;\n}\n\n.drawerBlock_bg {\n    background: rgba(14, 20, 18, 0.4);;\n    backdrop-filter: blur(2px);\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    z-index: 111;\n}\n\n@keyframes ani {\n    0% {\n        right: -100%\n    }\n\n    100% {\n        right: 0;\n    }\n}\n\n@keyframes ani2 {\n    0% {\n        right: 0\n    }\n\n    100% {\n        right: -100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
