// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    padding: 0 60px;
    display: flex;
    position: relative;
    justify-content: center;
    gap: 40px;
    background-color: white;
    z-index: 2;
}

.linkFooter {
    color: #000000;
}

.linkFooter:hover {
    color: #000000;
}

.footer_links {
    flex-wrap: wrap;
}

@media (max-width: 575.98px) {
    .footer {
        padding: 30px;
    }

    .footer_links {
        flex-direction: column;
        gap: 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .footer {
        padding: 30px;
    }

    .footer_links {
        flex-direction: column;
        gap: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}`, "",{"version":3,"sources":["webpack://./src/components/components/footer/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,SAAS;IACT,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ;;AAEA,qDAAqD;;AAErD,sDAAsD;;AAEtD,4BAA4B","sourcesContent":[".footer {\n    padding: 0 60px;\n    display: flex;\n    position: relative;\n    justify-content: center;\n    gap: 40px;\n    background-color: white;\n    z-index: 2;\n}\n\n.linkFooter {\n    color: #000000;\n}\n\n.linkFooter:hover {\n    color: #000000;\n}\n\n.footer_links {\n    flex-wrap: wrap;\n}\n\n@media (max-width: 575.98px) {\n    .footer {\n        padding: 30px;\n    }\n\n    .footer_links {\n        flex-direction: column;\n        gap: 10px;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .footer {\n        padding: 30px;\n    }\n\n    .footer_links {\n        flex-direction: column;\n        gap: 10px;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {}\n\n@media (min-width: 992px) and (max-width: 1199.98px) {}\n\n@media (min-width: 1200px) {}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
