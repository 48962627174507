// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findCouplePdf_task {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.findCouplePdf_task__item {
    display: flex;
    align-items: center;
    width: 100%;
}

.findCouplePdf_task__item__answer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.3cm;
}

.findCouplePdf_task__item__answer img {
    width: 2cm;
}`, "",{"version":3,"sources":["webpack://./src/components/pdf/games/findCouple/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".findCouplePdf_task {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.findCouplePdf_task__item {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.findCouplePdf_task__item__answer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    gap: 0.3cm;\n}\n\n.findCouplePdf_task__item__answer img {\n    width: 2cm;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
