"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STANDART = void 0;
exports.STANDART = "standart";
const TableTask_1 = require("./TableTask/TableTask");
const Quiz_1 = require("./Quiz/Quiz");
const ExtendedAnswer_1 = require("./ExtendedAnswer/ExtendedAnswer");
const Tasks = {
    tableTask: {
        component: TableTask_1.TableTask,
        checker: (task) => {
            const { positions } = task;
            positions["table"].forEach((position) => {
                if (!("row" in position) || !("col" in position)) {
                    return false;
                }
            });
            return true;
        },
    },
    extendedAnswer: { component: ExtendedAnswer_1.ExtendedAnswer, checker: () => true },
    quiz: { component: Quiz_1.Quiz, checker: () => true },
};
exports.default = Tasks;
