// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQBlock {
    padding: 0 60px;
    position: relative;
    z-index: 1000;
    margin-top: 100px;
    margin-bottom: 60px;
    min-height: 68vh;
}

.FAQBlock_title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
}

.FAQBlock_collapse {
    margin-top: 64px !important;
    border-radius: 32px !important;
    padding: 40px 24px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
    border-radius: 32px;
}`, "",{"version":3,"sources":["webpack://./src/views/faq/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,6BAA6B;IAC7B,8BAA8B;IAC9B,kDAAkD;IAClD,mBAAmB;AACvB","sourcesContent":[".FAQBlock {\n    padding: 0 60px;\n    position: relative;\n    z-index: 1000;\n    margin-top: 100px;\n    margin-bottom: 60px;\n    min-height: 68vh;\n}\n\n.FAQBlock_title {\n    color: #FFFFFF;\n    font-weight: 700;\n    font-size: 48px;\n    line-height: 60px;\n}\n\n.FAQBlock_collapse {\n    margin-top: 64px !important;\n    border-radius: 32px !important;\n    padding: 40px 24px !important;\n    background: #FFFFFF !important;\n    box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);\n    border-radius: 32px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
