import React from "react";
import fifthBG from "assets/backgrounds/steps/fifthBG.png";
import firstBG from "assets/backgrounds/steps/firstBG.png";
import fourthBG from "assets/backgrounds/steps/fourthBG.png";
import secondBG from "assets/backgrounds/steps/secondBG.png";
import thirdBG from "assets/backgrounds/steps/thirdBG.png";
import five from "assets/numbers/five.png";
import four from "assets/numbers/four.png";
import one from "assets/numbers/one.png";
import three from "assets/numbers/three.png";
import two from "assets/numbers/two.png";

import classic from "assets/themes/classic.png";
import clouds from "assets/themes/clouds.png";
import corkboard from "assets/themes/corkboard.png";
import jungle from "assets/themes/jungle.png";
import primary from "assets/themes/primary.png";
import space from "assets/themes/space.png";
import west from "assets/themes/west.png";

/*Patterns */
import anagram from "assets/patterns/anagram.svg";
import cards from "assets/patterns/cards.svg";
import checkYourSelf from "assets/patterns/checkYourSelf.svg";
import crossword from "assets/patterns/crossword.svg";
import cubes from "assets/patterns/cubes.svg";
import findCouple from "assets/patterns/findCouple.svg";
import groupDivision from "assets/patterns/groupDivision.svg";
import insertLetter from "assets/patterns/insertLetter.svg";
import insertLetter2 from "assets/patterns/insertLetter2.svg";
import matchElements from "assets/patterns/matchElements.svg";
import openWindow from "assets/patterns/openWindow.svg";
import quez from "assets/patterns/quez.svg";
import spaceMathematics from "assets/patterns/spaceMathematics.svg";
import writeAnswer from "assets/patterns/writeAnswer.svg";
import yesOrNot from "assets/patterns/yesOrNot.svg";

/*Servisces */
import anagramService from "assets/services/anagram.svg";
import cardsService from "assets/services/cards.svg";
import checkYourselfService from "assets/services/check_yourself.png";
import crosswordService from "assets/services/crossword.svg";
import cubesService from "assets/services/cubes.svg";
import findCoupleService from "assets/services/find_couple.svg";
import groupDivisionService from "assets/services/group_division.svg";
import insertLetterService from "assets/services/insert_letter.svg";
import insertLetterSecondService from "assets/services/insert_letter_second.svg";
import matchElementsService from "assets/services/match_elements.svg";
import openWindowService from "assets/services/open_window.svg";
import quizService from "assets/services/quiz.svg";
import spaceMathematicsService from "assets/services/space_mathematics.svg";
import writeAnswerService from "assets/services/write_answer.svg";
import yesOrNotService from "assets/services/yes_or_not.svg";

export const header = [
  {
    title: "tasks",
    link: "/tasks/all",
  },
  {
    title: "patterns",
    link: "/patterns",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
  {
    title: "subscription",
    link: "/subscriptions",
  },
  {
    title: "instructions",
    link: "/instructions",
  },
  // {
  //   title: 'search_header_link',
  //   link: '/search',
  // },
];

export const header_path = [
  "/",
  "/tasks",
  "/faq",
  "/subscriptions",
  "/patterns",
  "/profile",
  "/invite",
  "task",
  "reset-password",
];

export const header_user = [
  {
    title: "profile",
    link: "/profile",
  },
  {
    title: "favourites",
    link: "/profile/favourites",
  },
  {
    title: "mySubscription",
    link: "/profile/edit",
  },
  {
    title: "setting",
    link: "/profile/edit",
  },
];

export const footer = [
  {
    title: "main",
    link: "/",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
  {
    title: "tasks",
    link: "/tasks",
  },
  {
    title: "patterns",
    link: "/patterns",
  },
  {
    title: "subscription",
    link: "/subscriptions",
  },
];

export const footerPrivacy = [
  {
    title: "Политика конфеденциальности",
    link: "privacy",
  },
  // {
  //     title: 'Тарифные планы',
  //     link: 'tariff-plans'
  // },
  {
    title: "Реквизиты",
    link: "requisites",
  },
  {
    title: "Пользовательское соглашение",
    link: "agree",
  },
  {
    title: "Условия оплаты",
    link: "payment-policy",
  },
];

export const first_steps = [
  {
    step: "first",
    bg: firstBG,
    number: one,
  },
  {
    step: "second",
    bg: secondBG,
    number: two,
  },
  {
    step: "third",
    bg: thirdBG,
    number: three,
  },
  {
    step: "fourth",
    bg: fourthBG,
    number: four,
  },
  {
    step: "fifth",
    bg: fifthBG,
    number: five,
  },
];

export const faq = ["first", "second"];

export const prices = ["Start", "Standart", "Super"];

export const prices_desc = ["interactives", "printables", "resources"];

export const leader_board = [
  {
    rank: "1st",
    name: "Ammy",
    score: "11",
    time: "22.9",
  },
  {
    rank: "1st",
    name: "Ammy",
    score: "11",
    time: "22.9",
  },
  {
    rank: "1st",
    name: "Ammy",
    score: "11",
    time: "22.9",
  },
];

export const themes = [
  {
    img: classic,
    title: "classic",
  },
  {
    img: clouds,
    title: "clouds",
  },
  {
    img: corkboard,
    title: "corkboard",
  },
  {
    img: jungle,
    title: "jungle",
  },
  {
    img: primary,
    title: "primary",
  },
  {
    img: space,
    title: "space",
  },
  {
    img: west,
    title: "west",
  },
];

export const options_timer = ["no", "countUp", "countDown"];

export const options_random = ["true", "false"];

export const profile_country = ["Russia"];

export const profile_roly = ["true", "false"];

export const steps = [
  {
    title: "pattern",
    img: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 13.75L10 18.125L17.5 13.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 10L10 14.375L17.5 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "data",
    img: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.25 12.375H3.75C3.40482 12.375 3.125 12.6548 3.125 13V16.125C3.125 16.4702 3.40482 16.75 3.75 16.75H16.25C16.5952 16.75 16.875 16.4702 16.875 16.125V13C16.875 12.6548 16.5952 12.375 16.25 12.375Z"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.25 4H3.75C3.40482 4 3.125 4.27982 3.125 4.625V7.75C3.125 8.09518 3.40482 8.375 3.75 8.375H16.25C16.5952 8.375 16.875 8.09518 16.875 7.75V4.625C16.875 4.27982 16.5952 4 16.25 4Z"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "theme",
    img: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.625 2.5H16.25C16.4158 2.5 16.5747 2.56585 16.6919 2.68306C16.8092 2.80027 16.875 2.95924 16.875 3.125V11.25C16.875 11.4158 16.8092 11.5747 16.6919 11.6919C16.5747 11.8092 16.4158 11.875 16.25 11.875H3.75C3.58424 11.875 3.42527 11.8092 3.30806 11.6919C3.19085 11.5747 3.125 11.4158 3.125 11.25V5C3.125 4.33696 3.38839 3.70107 3.85723 3.23223C4.32607 2.76339 4.96196 2.5 5.625 2.5V2.5Z"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 11.875L8.125 16.25C8.125 16.7473 8.32254 17.2242 8.67417 17.5758C9.02581 17.9275 9.50272 18.125 10 18.125C10.4973 18.125 10.9742 17.9275 11.3258 17.5758C11.6775 17.2242 11.875 16.7473 11.875 16.25L11.25 11.875"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.125 8.75H16.875"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.125 2.5V5.625"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  // {
  //     title: "option",
  //     img: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <path d="M4.08602 7.15615C4.22664 6.85928 4.3907 6.57803 4.5782 6.30459L4.54695 4.27334C4.54695 4.08584 4.62508 3.90616 4.77352 3.78116C5.50008 3.17178 6.3282 2.67959 7.24227 2.35147C7.42195 2.28897 7.61727 2.30459 7.77352 2.40615L9.5157 3.45303C9.84383 3.42959 10.172 3.42959 10.5001 3.45303L12.2423 2.40615C12.4063 2.3124 12.6016 2.28897 12.7813 2.35147C13.672 2.67178 14.5079 3.14834 15.2501 3.77334C15.3907 3.89053 15.4766 4.07803 15.4688 4.26553L15.4376 6.29678C15.6251 6.57022 15.7891 6.85147 15.9298 7.14834L17.7032 8.13272C17.8673 8.22647 17.9845 8.38272 18.0157 8.57022C18.1798 9.4999 18.1876 10.4687 18.0157 11.414C17.9845 11.6015 17.8673 11.7577 17.7032 11.8515L15.9298 12.8358C15.7891 13.1327 15.6251 13.414 15.4376 13.6874L15.4688 15.7187C15.4688 15.9062 15.3907 16.0858 15.2423 16.2108C14.5157 16.8202 13.6876 17.3124 12.7735 17.6405C12.5938 17.703 12.3985 17.6874 12.2423 17.5858L10.5001 16.539C10.172 16.5624 9.84383 16.5624 9.5157 16.539L7.77352 17.5858C7.60945 17.6796 7.41414 17.703 7.23445 17.6405C6.34383 17.3202 5.50789 16.8437 4.7657 16.2187C4.62508 16.1015 4.53914 15.914 4.54695 15.7265L4.5782 13.6952C4.3907 13.4218 4.22664 13.1405 4.08602 12.8437L2.31258 11.8593C2.14852 11.7655 2.03133 11.6093 2.00008 11.4218C1.83602 10.4921 1.8282 9.52334 2.00008 8.57803C2.03133 8.39053 2.14852 8.23428 2.31258 8.14053L4.08602 7.15615Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  //         <path d="M10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  //     </svg>

  // },
  {
    title: "game",
    img: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.00003 16.8748C4.91795 16.8748 4.83668 16.8586 4.76085 16.8272C4.68502 16.7958 4.61612 16.7498 4.55808 16.6917C4.50004 16.6337 4.45401 16.5648 4.4226 16.4889C4.39119 16.4131 4.37502 16.3318 4.37503 16.2498L4.37507 12.9142C4.04165 13.0722 3.67371 13.1432 3.30547 13.1207C2.93723 13.0982 2.58065 12.983 2.26891 12.7857C1.95717 12.5884 1.70039 12.3155 1.52246 11.9923C1.34453 11.6691 1.25122 11.3062 1.25122 10.9373C1.25122 10.5684 1.34453 10.2054 1.52246 9.88224C1.70039 9.55906 1.95717 9.28613 2.26891 9.08884C2.58065 8.89155 2.93723 8.77631 3.30547 8.75384C3.67371 8.73136 4.04165 8.80239 4.37507 8.9603L4.37503 5.62478C4.37503 5.5427 4.39119 5.46143 4.4226 5.3856C4.45401 5.30977 4.50005 5.24087 4.55809 5.18283C4.61612 5.12479 4.68502 5.07876 4.76085 5.04735C4.83668 5.01594 4.91796 4.99977 5.00004 4.99977L8.64805 4.99982C8.49013 4.6664 8.41911 4.29846 8.44158 3.93022C8.46405 3.56198 8.5793 3.20541 8.77658 2.89367C8.97387 2.58193 9.2468 2.32515 9.56998 2.14722C9.89316 1.96928 10.2561 1.87598 10.625 1.87598C10.9939 1.87598 11.3569 1.96928 11.68 2.14722C12.0032 2.32515 12.2762 2.58193 12.4734 2.89367C12.6707 3.20541 12.786 3.56198 12.8084 3.93022C12.8309 4.29846 12.7599 4.6664 12.602 4.99982L16.25 4.99977C16.4157 4.99977 16.5747 5.06562 16.6919 5.18283C16.8091 5.30004 16.875 5.45901 16.875 5.62477L16.875 8.9603C16.5416 8.80239 16.1737 8.73136 15.8054 8.75384C15.4372 8.77631 15.0806 8.89155 14.7689 9.08884C14.4571 9.28613 14.2004 9.55906 14.0224 9.88224C13.8445 10.2054 13.7512 10.5683 13.7512 10.9373C13.7512 11.3062 13.8445 11.6691 14.0224 11.9923C14.2004 12.3155 14.4571 12.5884 14.7689 12.7857C15.0806 12.983 15.4372 13.0982 15.8054 13.1207C16.1737 13.1432 16.5416 13.0722 16.875 12.9142L16.875 16.2498C16.875 16.4155 16.8091 16.5745 16.6919 16.6917C16.5747 16.8089 16.4158 16.8748 16.25 16.8748L5.00003 16.8748Z"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

export const services = [
  {
    step: "first",
    preview: groupDivisionService,
    game_type: "TYPE_GROUPS_DIVISION",
  },
  {
    step: "second",
    preview: cardsService,
    game_type: "TYPE_CARDS",
  },
  {
    step: "third",
    preview: openWindowService,
    game_type: "TYPE_OPEN_WINDOW",
  },
  {
    step: "fourth",
    preview: quizService,
    game_type: "TYPE_QUIZ",
  },
  {
    step: "fifth",
    preview: findCoupleService,
    game_type: "TYPE_FIND_COUPLE",
  },
  {
    step: "sixth",
    preview: matchElementsService,
    game_type: "TYPE_MATCH_ELEMENTS",
  },
  {
    step: "seventh",
    preview: insertLetterService,
    game_type: "TYPE_INSERT_LETTER",
  },
  {
    step: "eighth",
    preview: anagramService,
    game_type: "TYPE_ANAGRAM",
  },
  {
    step: "ninth",
    preview: writeAnswerService,
    game_type: "TYPE_WRITE_ANSWER",
  },
  {
    step: "tenth",
    preview: crosswordService,
    game_type: "TYPE_CROSSWORD",
  },
  {
    step: "eleventh",
    preview: cubesService,
    game_type: "TYPE_CUBES",
  },
  {
    step: "twelfth",
    preview: yesOrNotService,
    game_type: "TYPE_YES_OR_NOT",
  },
  {
    step: "thirteenth",
    preview: spaceMathematicsService,
    game_type: "TYPE_SPACE_MATHEMATICS",
  },
  {
    step: "fourteenth",
    preview: insertLetterSecondService,
    game_type: "TYPE_INSERT_LETTER_SECOND",
  },
  {
    step: "fifteen",
    preview: checkYourselfService,
    game_type: "TYPE_CHECK_YOURSELF",
  },
  {
    step: "sixteen",
    preview: writeAnswerService, //todo заменить иконку
    game_type: "TYPE_WRITE_EXTENDED_ANSWER",
  },
];

export const patternsGame = [
  {
    title: "Деление на группы",
    description: "Распредели все элементы по группам.",
    icon: groupDivision,
    type: "TYPE_GROUPS_DIVISION",
    style: "purpule",
  },
  {
    title: "Карточки",
    description: "Перелистывай карточки и выполняй задание на каждой из них.",
    icon: cards,
    type: "TYPE_CARDS",
    style: "blue",
  },
  {
    title: "Открой окно",
    description: "Открой каждое окно и узнай, что внутри.",
    icon: openWindow,
    type: "TYPE_OPEN_WINDOW",
    style: "green",
  },
  {
    title: "Викторина",
    description: "Прочитай задание и выбери правильный ответ.",
    icon: quez,
    new: true,
    type: "quiz",
    style: "green",
  },
  {
    title: "Найди пару",
    description: "Каждому элементу подбери пару.",
    icon: findCouple,
    type: "TYPE_FIND_COUPLE",
    style: "purpule",
  },
  {
    title: "Соотнеси элементы",
    description: "Найди соответствующие друг другу элементы.",
    icon: matchElements,
    type: "TYPE_MATCH_ELEMENTS",
    style: "blue",
  },
  {
    title: "Вставь слово",
    description: "Заполни пропуски, вставляя нужные слова.",
    icon: insertLetter,
    type: "TYPE_INSERT_LETTER",
    style: "blue",
  },
  {
    title: "Анаграмма",
    description: "Расставь буквы в правильном порядке и прочитай слово.",
    icon: anagram,
    type: "TYPE_ANAGRAM",
    style: "green",
  },
  {
    title: "Напиши ответ",
    description: "Прочитай задание и напиши ответ.",
    icon: writeAnswer,
    type: "TYPE_WRITE_ANSWER",
    style: "purpule",
  },
  {
    title: "Кроссворд",
    description: "Прочитай вопросы, напиши ответы",
    icon: crossword,
    type: "TYPE_CROSSWORD",
    style: "purpule",
  },
  {
    title: "Кубики",
    description: "Читай и выполняй задания на кубиках",
    icon: cubes,
    type: "TYPE_CUBES",
    style: "blue",
  },
  {
    title: "Да/Нет",
    description: "Это ложь или истина? Выбери правильный ответ",
    icon: yesOrNot,
    type: "TYPE_YES_OR_NOT",
    style: "green",
  },
  {
    title: "Космическая математика",
    description: "Выполни действия и выбери верный ответ",
    icon: spaceMathematics,
    type: "TYPE_SPACE_MATHEMATICS",
    style: "green",
  },
  {
    title: "Онлайн тренажер",
    description: "Вставь пропущенные буквы в слова",
    icon: insertLetter2,
    type: "TYPE_INSERT_LETTER_SECOND",
    style: "purpule",
  },
  {
    title: "Проверь себя",
    description: "Эффективная самопроверка, активное запоминание",
    icon: checkYourSelf,
    type: "TYPE_CHECK_YOURSELF",
    style: "blue",
  },
  {
    title: "Расширенный ответ",
    description: "Ответьте на вопросы более подробно.",
    icon: writeAnswer, //todo заменить иконку
    new: true,
    type: "extendedAnswer",
    style: "blue",
  },
  {
    title: "Таблица",
    description: "Заполните таблицу ответами на пересечении вопросов",
    icon: openWindow,
    new: true,
    type: "tableTask",
    style: "blue",
  },
];
