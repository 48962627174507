// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d8eWIi6fghcRXemN6pr9 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 0.6rem;
  width: 60%;
  flex: 1;
}

@media (max-width: 650px) {
  .d8eWIi6fghcRXemN6pr9 {
    width: 75%;
  }
}

@media (max-width: 480px) {
  .d8eWIi6fghcRXemN6pr9 {
    width: 85%;
  }
}
`, "",{"version":3,"sources":["webpack://./../exercise/src/components/Forms/Steps/TaskResolve.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,cAAc;EACd,WAAW;EACX,UAAU;EACV,OAAO;AACT;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".taskResolve {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  margin: 0 auto;\n  gap: 0.6rem;\n  width: 60%;\n  flex: 1;\n}\n\n@media (max-width: 650px) {\n  .taskResolve {\n    width: 75%;\n  }\n}\n\n@media (max-width: 480px) {\n  .taskResolve {\n    width: 85%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var taskResolve = `d8eWIi6fghcRXemN6pr9`;
export default ___CSS_LOADER_EXPORT___;
