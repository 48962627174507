// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ypNDJkYcXxc8aEkjFrol {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.DnIgzpHQMD1Ip9bzW2Cq {
    width: 50px;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/backgrounds/checkYourself/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":[".container {\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: center;\n}\n\n.item {\n    width: 50px;\n    height: 50px;\n    background-color: white;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n    border: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
export var container = `ypNDJkYcXxc8aEkjFrol`;
export var item = `DnIgzpHQMD1Ip9bzW2Cq`;
export default ___CSS_LOADER_EXPORT___;
