"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultConfigTypes = exports.StepExerciseType = exports.AnswerType = void 0;
var AnswerType;
(function (AnswerType) {
    AnswerType["SIMPLE"] = "simple";
    AnswerType["MULTIPLY"] = "multiply";
})(AnswerType || (exports.AnswerType = AnswerType = {}));
var StepExerciseType;
(function (StepExerciseType) {
    StepExerciseType["RESOLVE"] = "resolve";
    StepExerciseType["RESULTS"] = "results";
})(StepExerciseType || (exports.StepExerciseType = StepExerciseType = {}));
var ResultConfigTypes;
(function (ResultConfigTypes) {
    ResultConfigTypes["MANY_MANY"] = "manyMany";
    ResultConfigTypes["ONE_MANY"] = "oneMany";
})(ResultConfigTypes || (exports.ResultConfigTypes = ResultConfigTypes = {}));
