// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575.98px) {
    .taskInfo_actions {
        flex-direction: column;
        align-items: center;
        justify-content: center !important;
    }

    .taskInfo_actions .patternInfoBlock_actions {
        gap: 10px;
    }

    .taskInfo_actions .patternInfoBlock_actions button {
        margin-top: 0;
    }

    .taskInfo_actions span {
        margin-right: 0 !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .taskInfo_actions {
        flex-direction: column;
        align-items: flex-end;
    }

    .taskInfo_actions .patternInfoBlock_actions button {
        margin-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .taskInfo_actions {
        flex-direction: column;
        align-items: flex-end;
    }

    .taskInfo_actions .patternInfoBlock_actions button {
        margin-top: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/taskInfo/media.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,kCAAkC;IACtC;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,qBAAqB;IACzB;;IAEA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,qBAAqB;IACzB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media (max-width: 575.98px) {\n    .taskInfo_actions {\n        flex-direction: column;\n        align-items: center;\n        justify-content: center !important;\n    }\n\n    .taskInfo_actions .patternInfoBlock_actions {\n        gap: 10px;\n    }\n\n    .taskInfo_actions .patternInfoBlock_actions button {\n        margin-top: 0;\n    }\n\n    .taskInfo_actions span {\n        margin-right: 0 !important;\n    }\n}\n\n@media (min-width: 576px) and (max-width: 767.98px) {\n    .taskInfo_actions {\n        flex-direction: column;\n        align-items: flex-end;\n    }\n\n    .taskInfo_actions .patternInfoBlock_actions button {\n        margin-top: 0;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 991.98px) {\n    .taskInfo_actions {\n        flex-direction: column;\n        align-items: flex-end;\n    }\n\n    .taskInfo_actions .patternInfoBlock_actions button {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
